import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { AddAsssessmenttwo, GETSCOPEOPTIONDATA, getAsssessmentTwo } from "../../../Utils/services";
import AuthContext from "../../../Utils/auth-context";

function TableRow({ data, index, manthFilter, YearData, setloader }) {
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [rowData, setRowData] = useState({});
  const [unit, setUnit] = useState([]);
  const { plant } = useContext(AuthContext);

  function handleInputChange(event) {
    const { name, value } = event.target;
    if (name == "unit") {


      setRowData((prevData) => ({ ...prevData, [name]: value }));

    }
    else {
      setRowData((prevData) => ({ ...prevData, [name]: value }));
    }


  }

  const allAssessment = async () => {
    setRowData({})
    setloader(true);
    const year_data = localStorage.getItem("form")
    try {
      const result = await getAsssessmentTwo({
        assessment_month: manthFilter,
        category: "Purchased Electricity",
        plant_type: plant,
        assessment_year: year_data
      });



      if (result) {
        let filterData = result.filter((item) => item?.assessment_month === manthFilter);

        if (filterData.length === 0) {
          setRowData({
            id: data.id,
            plant_type: plant,
            assessment_month: data?.assessment_month,
            source_name: "",
            unit: "",
            emission_value: '',
            consumed_power: "",
            category: "Purchased Electricity",
            created_on: data.created_on,
            layout_type: "2",
            scope_data: ""
          });
          setloader(false);
        } else {
          filterData[0].unit = filterData[0].scope_data;
          setRowData(filterData[0]); // Assuming setRowData expects an array
          

          setloader(false);

        }
      }
    } catch (error) {
      toast.error(error);
      setloader(false);
    }
  };


  const Frcth = async () => {
    setloader(true);
    const result2 = await GETSCOPEOPTIONDATA(2, 2);
    if (result2?.res && result2?.res?.length > 0) {
      setUnit(result2?.res);
      setloader(false);
    }
  }
  useEffect(() => {
    allAssessment();
  }, [plant, manthFilter, unit]);
  useEffect(() => {
    Frcth();
  }, []);
  const validateFields = () => {
    let formIsValid = true;
    const newErrors = {};
    if (!rowData.source_name) {
      formIsValid = false;
      newErrors.source_name = "Please enter the Source";
    }

    else if (!isNaN(rowData.source_name)) {
      formIsValid = false;
      newErrors.source_name = "Source must be a String.";
    }
    if (!rowData.consumed_power) {
      formIsValid = false;
      newErrors.consumed_power = "Please enter the Consumed Power.";
    } else if (isNaN(rowData.consumed_power)) {
      formIsValid = false;
      newErrors.consumed_power = "Consumed Power must be a number.";
    }
    if (rowData.consumed_power < 0) {
      formIsValid = false;
      newErrors.consumed_power = "Consumed Power cannot be less than 0";
    }
    if (!rowData.unit) {
      formIsValid = false;
      newErrors.consumed_power = "Please enter the unit";
    }
    setErrors(newErrors);
    return formIsValid;
  };

  const handleSaveClick = async (id) => {
    try {
      if (validateFields()) {
        setloader(true);
        let filterData = unit?.filter((item) => item?.scope_data === rowData?.unit);
        const result = await AddAsssessmenttwo({
          scopes2: [
            {
              plant_type: plant,
              id: rowData?.id,
              assessment_month: rowData?.assessment_month,
              source_name: rowData?.source_name,
              consumed_power: rowData?.consumed_power,
              category: "Purchased Electricity",
              created_on: rowData.created_on,
              layout_type: "2",
              assessment_year: YearData,
              unit: filterData[0]?.unit ? filterData[0]?.unit : "",
              emission_value: filterData[0]?.value ? filterData[0]?.value : 1,
              scope_data: filterData[0]?.scope_data ? filterData[0]?.scope_data : '',
            },
          ],
        });
        setloader(false);
        await allAssessment();
        setIsEditing(false);
        toast.success(result.message || "Data Successfully added and updated");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  if (isEditing) {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>
          <input name="created_on" value={rowData.created_on} disabled />
        </td>
        <td>
          <input name="source_name" value={rowData.source_name} onChange={handleInputChange} placeholder="Name of Electricity Board" />
          {errors.source_name && <span className="error">{errors.source_name}</span>}
        </td>
        <td>
          <select name="unit" value={rowData.unit} onChange={handleInputChange} >
            <option value=''>Select Unit</option>
            {unit && unit.map((res) => (
              <option key={res?.scope_data} value={res?.scope_data}>{res?.name}  , {(res?.unit)}</option>
            ))}
          </select>
          {errors.unit && <span className="error" >{errors.unit}</span>}
        </td>
        <td>
          <input name="consumed_power" value={rowData.consumed_power} onChange={handleInputChange} />
          {errors.consumed_power && <span className="error" >{errors.consumed_power}</span>}
        </td>
        <td>
          <button className="edit-btn-table" onClick={() => handleSaveClick(index + 1)}>
            Save
          </button>
        </td>
      </tr>
    );
  }

  return (
    <>
      {Object.keys(rowData).length !== 0 && (
        <tr>
          <td className="p-2">{index + 1}</td>
          <td className="p-2">
            <input name="created_on" className="rowInput" value={rowData?.created_on} disabled />
          </td>
          <td className="p-2">
            <input className="rowInput" name="source_name" value={rowData?.source_name} disabled placeholder="Name of Electricity Board" />
          </td>
          <td>
            <select name="unit" value={rowData.unit} onChange={handleInputChange} disabled>
              <option value=''>Select Unit</option>
              {unit && unit.map((res) => (
                <option key={res?.scope_data} value={res?.scope_data}>{res?.name}  , {(res?.unit)}</option>
              ))}
            </select>
            {errors.unit && <span className="error" >{errors.unit}</span>}
          </td>
          <td className="p-2">
            <input className="rowInput" name="consumed_power" value={rowData?.consumed_power} disabled />
          </td>
          <td className="p-2">
            <button className="edit-btn-table" onClick={() => setIsEditing(true)}>
              Edit
            </button>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRow;
