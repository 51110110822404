
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, GETSCOPEOPTIONDATA, deleteAsssessmentThree, getAsssessmentThree, SCOPE3DATAPi, GETCATEGORYAPi, POSTEMISSIONAPi } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../../Common/DeleteConfirm";
import Date from "../../Date";
import AutoSuggestion from "../../AutoSuggestion"


const InboundTable = ({ month, YearData, CreatOn, manthFilter, setloader, monYear }) => {

    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [gettrnsport, setGettrnsport] = useState();
    const [options, setOptions] = useState({ option1: [], option2: [] });
    const { plant } = useContext(AuthContext);
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { waybill_date: "", origin_place: "", delivery_place: "", transport_mode: "", fuel_type: "", covered_distance: '', plant_type: plant, category: "Inbound Transport", assessment_month: month, assessment_year: year_data, created_on: CreatOn, unit: '', emission_value: '' }]);
    }
    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };


    const updateDate = (index, waybill_date) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].waybill_date = waybill_date
        setFuelDetails(newFuelDetails)

    }

    const updateNameOforigin = (index, origin_place) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].origin_place = origin_place
        setFuelDetails(newFuelDetails)


    }



    const updatedeliveryplace = (index, delivery_place) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].delivery_place = delivery_place
        setFuelDetails(newFuelDetails)

    }

    const updateModetransport = (index, transport_mode) => {

        let filter_data = options.option1?.filter((item) => item.id === transport_mode);

        const newFuelDetails = [...fuelDetails]

        newFuelDetails[index].transport_mode = filter_data[0]?.label
        setFuelDetails(newFuelDetails)

    }

    const updateFueltype = (index, fuel_type) => {
        let filter_data = options.option2?.filter((item) => item?.id == fuel_type);

        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].fuel_type = filter_data[0]?.label
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].emission_value = filter_data[0]?.value
        newFuelDetails[index].scope_data = filter_data[0]?._value
        setFuelDetails(newFuelDetails)
    }


    const updateDistance = (index, covered_distance) => {
        let filter_data = options.option1?.filter((item) => item?.label == fuelDetails[index]?.covered_distance);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].covered_distance = covered_distance;

        setFuelDetails(newFuelDetails)
    }

    const SaveData = async () => {

        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    scopes3: fuelDetails,
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };

    const getSelectData = async () => {
       
        const result = await SCOPE3DATAPi(3, 11);
        const datas1 = result?.res?.map((item) => ({
            id: item?.category_name,
            label: item?.category_name,
            unit: item?.category_name,
            value: item?.category_name,
            _value: item?.category_name,
        }));


        setOptions((prevState) => ({
            ...prevState,
            option1: datas1,

        }));

    }
    const getFuel = async () => {

        const data =
        {
            'sub_category': gettrnsport,
            'category': 11
        }
        const category_data = await POSTEMISSIONAPi(data);
        const datas1 = category_data?.data?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));


        setOptions((prevState) => ({
            ...prevState,
            option2: datas1,

        }));

    }
    useEffect(() => {

        getFuel();
    }, [gettrnsport]);

    useEffect(() => {
        allAssessment();
        getSelectData();
    }, [month, plant]);

    const allAssessment = async () => {
        setloader(true);
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree({
            plant_type: plant,
            category: "Inbound Transport",
            assessment_month: manthFilter,
            assessment_year: yaer_data
        });
        setloader(false)
        let filterData = result?.filter((item) => item?.assessment_month === manthFilter);
        setFuelDetails(filterData);
    };

    const validateFields = () => {

        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};
            if (!fuel.waybill_date) {
                formIsValid = false;
                errors.waybill_date = 'Please enter the date of invoice.';
            }
            if (!fuel.origin_place) {
                formIsValid = false;
                errors.origin_place = 'Please  enter the origin place';
            }
            else if (!isNaN(fuel.origin_place)) {
                formIsValid = false;
                errors.origin_place = 'origin place must be a string.';
            }
            if (!fuel.delivery_place) {
                formIsValid = false;
                errors.delivery_place = 'Please  enter the   delivery place';
            }
            else if (!isNaN(fuel.delivery_place)) {
                formIsValid = false;
                errors.delivery_place = 'delivery place must be a string.';
            }
            if (!fuel.fuel_type) {
                formIsValid = false;
                errors.fuel_type = 'Please  select the fuel type';
            }
            if (!fuel.transport_mode) {
                formIsValid = false;
                errors.transport_mode = 'Please  select the transport mode';
            }

            if (!fuel.covered_distance && fuel.covered_distance !== 0) {
                formIsValid = false;
                errors.covered_distance = 'Please enter the Distance Covered.';
            }
            else if (isNaN(fuel.covered_distance)) {
                formIsValid = false;
                errors.covered_distance = 'Distance Covered must be a Number.';
            }
            else if (fuel.covered_distance < 0) {
                formIsValid = false;
                errors.covered_distance = 'Distance Covered must be a greater than -1.';
            }
            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };

    useEffect(() => {
        allAssessment();
        getSelectData();
    }, [month, plant]);

    return (
        <div>
            <div className='plant-table mt-4' style={{ 'overflow-x': 'visible' }}>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Date of Waybill </th>
                            <th>Place of Origin </th>
                            <th>Place of Delivery</th>
                            <th>Mode of Transport</th>
                            <th>Type of Fuel</th>
                            <th>Distance Covered</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {

                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            const isQuantityDisabled = !isRowEdited || !fuel.fuel_type || !fuel.transport_mode;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <Date index={index} monYear={monYear} handle={updateDate} value={fuel.waybill_date} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.waybill_date && <div className="error">{hasErrors.waybill_date}</div>}
                                    </td>
                                    <td>
                                        <input
                                            type='text' disabled={!isRowEdited}
                                            value={fuel.origin_place}
                                            onChange={(event) =>
                                                updateNameOforigin(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.origin_place && <div className="error">{hasErrors.origin_place}</div>}
                                    </td>
                                    <td>
                                        <input
                                            type='text' disabled={!isRowEdited}
                                            value={fuel.delivery_place}
                                            onChange={(event) =>
                                                updatedeliveryplace(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.delivery_place && <div className="error">{hasErrors.delivery_place}</div>}
                                    </td>
                                    <td>

                                        <AutoSuggestion data={options.option1} prevalue={fuel.transport_mode} updateNameOfChemical={updateModetransport} index={index} disabled={!isRowEdited} setField={setGettrnsport} />
                                        {hasErrors && hasErrors.transport_mode && <div className="error">{hasErrors.transport_mode}</div>}
                                    </td>
                                    <td>

                                        <AutoSuggestion data={options.option2} prevalue={fuel.fuel_type} updateNameOfChemical={updateFueltype} index={index} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.fuel_type && <div className="error">{hasErrors.fuel_type}</div>}
                                    </td>


                                    <td>
                                        <input
                                            type='text' disabled={isQuantityDisabled}
                                            value={fuel.covered_distance}
                                            onChange={(event) =>
                                                updateDistance(index, event.target.value)
                                            }
                                        />
                                        {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                        {hasErrors && hasErrors.covered_distance && <span className="error">{hasErrors.covered_distance}</span>}

                                    </td>
                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InboundTable;
