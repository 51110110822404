import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import Inbound_plant from '../../../Components/Common/ScopeThreeTables/Inbound/Inbound_plant'
import Outbound_plant from '../../../Components/Common/ScopeThreeTables/Outbound/Outbound_plant'
import Row_materialplant from '../../../Components/Common/ScopeThreeTables/Row-materials/Row_materialplant'

const OutboundTransport = () => {


    return (
        <div>
            <InnerHeader innertitle="Outbound Transport" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Outbound Transport" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <Outbound_plant />
            </div>
        </div>
    )
}

export default OutboundTransport;