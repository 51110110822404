import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import Employee_Plant from '../../../Components/Common/ScopeThreeTables/Employee_Commuting/Employee_Plant'
import Outbound_plant from '../../../Components/Common/ScopeThreeTables/Outbound/Outbound_plant'


const EmployeeCommuting = () => {


    return (
        <div>
            <InnerHeader innertitle="Employee Commuting" title="Scope 3"   />
            <div className='px-16'>
                <Backbtn title="Employee Commuting" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <Employee_Plant />
            </div>
        </div>
    )
}

export default EmployeeCommuting;