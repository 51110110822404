import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import SoldProductPlant from '../../../Components/Common/ScopeThreeTables/SoldProduct/SoldProductPlant'

const SoldProduct = () => {
    return (
        <div>
            <InnerHeader innertitle="Use of Sold Products" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Use of Sold Products" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <SoldProductPlant />
            </div>
        </div>
    )
}

export default SoldProduct