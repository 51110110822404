import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Login_LOGO from '../../Assets/images/logo.png'
// import logo from "../../Assets/images/sidenav/new_logo.png"
import AuthContext from '../../Utils/auth-context';
import enscopelogo from "../../Assets/images/sidenav/new_logo.png"
import { loginService, getYear } from '../../Utils/services';



const Login = () => {
  const [userData, setUserData] = useState({ email: "", password: "", });
  const [loading, setloading] = useState(false)
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const loginSubmit = async (e) => {
    e.preventDefault();

    try {
      setloading(true)
      const result = await loginService({
        "email": userData.email,
        "password": userData.password,
      });

      localStorage.setItem('ALLDATA', result?.data.access_token_expiresIn);
      if (result.status === 200) {
        authCtx.login(result?.data?.access_token, result?.data?.refresh_token);
        localStorage.setItem('company_name', result?.data?.User?.company_name);
        if (result?.data?.User?.step != 4) {

          localStorage.setItem('email', result?.data?.User?.email);


          toast.success(result.message);
          setTimeout(() => {
            // Navigate to '/signup' after 2 seconds
            if (result?.data?.User?.step == 2) {

              navigate("/billing", { state: result?.data });
            }
            else if (result?.data?.User?.step == 3) {
              navigate("/addplant", { state: result?.data });
            }
            else {
              navigate('/signup');
            }


            // Set an item in localStorage
            localStorage.setItem('step', result?.data?.User?.step);
          }, 2000);

        }
        else if (result?.data?.User?.Admin == 0 && result?.data?.User?.step == 4) {

          toast.success(result.message || "User Authenticated!");
          authCtx.login(result?.data?.access_token, result?.data?.refresh_token);
          setloading(false);
          localStorage.setItem('userID', result.data.User.id);
          const Filtered = async () => {
            try {
              setloading(true)
              try {
                let year = await getYear();
                if (year?.res?.status == 200) {
                  localStorage.setItem("form", year?.res?.data[0]?.year);
                }
              } catch (error) {
                setloading(false)
                console.error("An error occurred:", error);
              }

            }
            catch (err) {
              setloading(false);
              toast.error(err)
            }




          }
          navigate('/');
        }
        else {
          toast.error("You have not neccessary permission");
          setloading(false);

        }
      } else if (result.status == 401) { // Unauthorized error
        setloading(false);
        toast.error("Unauthorized: Invalid credentials");
      } else {
        setloading(false);
        toast.error(result.message || "Login failed");
      }




    } catch (err) {
      setloading(false);
      toast.error(err.message || "An error occurred");
    }
  };


  const handleSubmit = (e) => {
    loginSubmit(e)
  }

  const handleSingUp = () => {
    navigate('/signup');
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setUserData({
      ...userData,
      [name]: value,
    })
  }


  return (
    <div>
      <div class="absCenter is-Responsive">
        <div id="logo-container">
          <img class="img-responsive logo-naic" src={Login_LOGO} alt="NAIC Logo" />
          <div className="login_title">
            <h4 className="text-center">Log In</h4>
          </div>
        </div>


        <form action="" id="loginForm" className="mt-8" onSubmit={e => handleSubmit(e)}>
          <div class="form-group input-group login-input">
            <p className='mb-3'>Email Address</p>
            <input
              class="form-control"
              type="email"
              name="email"
              placeholder="Enter your email id"
              onChange={handleChange}
              required
            />
          </div>
          <div class="form-group input-group login-input ">
            <p className='mb-3'>Password</p>
            <input
              class="form-control"
              type="password"
              name="password"
              placeholder="Enter password"
              onChange={handleChange}
              required
            />
          </div>
          {/* <div class="footer flex items-center justify-end mb-5">
            <span className="px-2 cursor-pointer" onClick={() => handleSingUp()} >Forgot Password?</span>
          </div> */}

          <div class="form-group">
            <button type="submit" class="btn btn-def btn-block ">
              {loading ?
                <div className='flex items-center justify-center'>
                  <div
                    class="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    ></span>
                  </div>
                  <span className='px-2'>Loading...</span>
                </div> : "Login "}
            </button>
          </div>
        </form>
        <div class="footer flex items-center justify-center mt-6">
          <p>Don’t have an account?</p>
          <span className="px-4 cursor-pointer" onClick={() => handleSingUp()} >Sign Up</span>
        </div>
      </div>
    </div>
  )
}

export default Login
