import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import FranchiseFuelConPlant from '../../../Components/Common/ScopeThreeTables/FranchiseFuelCons/FranchiseFuelConPlant'

const Ffc = () => {
    return (
        <div>
            <InnerHeader  innertitle="Franchise Fuel Consumption" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Franchise Fuel Consumption" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <FranchiseFuelConPlant />
            </div>
        </div>
    )
}

export default Ffc