import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import jsonData from '../../Desc.json'; // Make sure to provide the correct path

const Descriptions = () => {
    const [description, setDescription] = useState("");
    const { discrip } = useParams();
    const location = useLocation();

    useEffect(() => {
        function getRouteDescriptionFromUrl(url, jsonData) {

            try {
                const pathSegments = url.split('/');
                const routeName = pathSegments[pathSegments.length - 1];

                const routes = jsonData.routes;
                for (const route of routes) {
                    if (route.Parameters === routeName) {
                        return route.Description;
                    }
                }

                return `.`;
            } catch (error) {
                return "Invalid JSON format. Please provide a valid JSON with 'routes' array.";
            }
        }

        const fullUrl = `${location.pathname}`;
        const result = getRouteDescriptionFromUrl(fullUrl, jsonData);
        setDescription(result);
    }, [location, discrip, jsonData]);

    return (
        <div className='inner_desc'>
            <p>{description}</p>
        </div>
    );
};

export default Descriptions;
