import React, { useContext, useEffect, useState } from "react";
import { FINANCIAYEARAPi, GETSCOPEOPTIONDATA, getAsssessmentTwo, getYear } from "../../../Utils/services";
import TableRow from "./TableRow";
import AuthContext from "../../../Utils/auth-context";
import Loader from "../../Common/Loader"

function ElectricityPlantTable() {
    const [electricData, setElectricData] = useState([])
    const [loader, setloader] = useState(false);
    const getAssessmentTwoApi = async () => {
        let curryear = localStorage.getItem("form");
        if (curryear) {
            setloader(true);
            try {
                let _year = curryear.split('-')[0]
                let result = await FINANCIAYEARAPi(_year);
                if (result?.res) {
                    setElectricData(result?.res);
                }

            } catch (error) {
                setloader(false);
                // Handle the error here, you can log it or show a message to the user
                console.error("Error fetching year data:", error);
            }
            finally {
                setloader(false);
            }



        }
    }

    useEffect(() => {
        getAssessmentTwoApi()
    }, [])

    return (
        <>

            <div className='plant-table mt-4'>

                <table>

                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Month</th>
                            <th>Source of Electricity</th>
                            <th>Unit</th>
                            <th>Electricity Consumed</th>
                            <th>Action</th>
                        </tr>

                    </thead>

                    <tbody>

                        {electricData?.map((rowData, index) => (
                            <TableRow key={rowData.id} data={rowData} index={index} YearData={rowData?.year} manthFilter={rowData?.assessment_month} setloader={setloader} />
                        ))}
                    </tbody>
                </table>
            </div>
            <Loader loader={loader} />
        </>

    );
}

export default ElectricityPlantTable;