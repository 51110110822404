import React, { useState } from 'react';

const CemaRow = ({ index, datas, isEditing, setdatas, setSave }) => {
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newInputList = [...datas];
    newInputList[index][name] = value; // Update the property name in the array

    // Validate input value
    if (name === 'quantity') {
      if (!value) {
        setSave(true);
        setErrors({ ...errors, [name]: 'Quantity is Required' });
      } else if (isNaN(parseFloat(value))) {
        setSave(true);
        setErrors({ ...errors, [name]: 'Quantity must be a Number' });
      } else if (parseFloat(value) < 0) {
        setSave(true);
        setErrors({ ...errors, [name]: 'Quantity must be greater than -1' });
      } else {
        setSave(false);
        setErrors({ ...errors, [name]: null });
      }
    }

    setdatas(newInputList); // Update the state with the modified input list
  };

  if (isEditing) {
    return (
      <tr>
        <td>{index + 1}</td>
        <td className="p-2">
          <input name="carbon_emission" className="rowInput" value={datas[index]?.carbon_emission} disabled />
        </td>
        <td>
          <input
            name="quantity"
            value={datas[index]?.quantity}
            onChange={handleInputChange}

          />
          {errors.quantity && <span className="error">{errors.quantity}</span>}
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className="p-2">{index + 1}</td>
      <td className="p-2">
        <input name="carbon_emission" className="rowInput" value={datas[index]?.carbon_emission} disabled />
      </td>
      <td className="p-2">
        <input className="rowInput" name="quantity" value={datas[index]?.quantity} disabled />
      </td>
    </tr>
  );
};

export default CemaRow;