import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import SecPlant from '../../../Components/Common/ScopeThreeTables/SecPlant/SecPlant'

const Sec = () => {
    return (
        <div>
            <InnerHeader  innertitle="Supplier Electricity Consumption" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Supplier Electricity Consumption" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <SecPlant />
                {/* <GoodServicesPlant /> */}
            </div>
        </div>
    )
}

export default Sec