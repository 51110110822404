import React, { useContext, useState } from 'react'
import { ModalContext } from '../../Context';
import stationIcon from "../../Assets/images/icons/local_gas_station.svg"
import electric from "../../Assets/images/icons/electric_bolt.svg"
import steamicon from "../../Assets/images/icons/onsen.svg"
import fireicon from "../../Assets/images/icons/local_fire_department.svg"
import cools from "../../Assets/images/icons/mode_cool.svg"
import { useNavigate } from 'react-router-dom';
import { ViewScopeApi, getYear } from '../../Utils/services';
import Loader from "../../Components/Common/Loader";
import info from "../../Assets/images/icons/info.svg"
import DescModal from './DescModal';
import { toast } from 'react-toastify';



const ScopeLabstwo = () => {
    const [loader, setloader] = useState(false)
    const [innerData, setinnerData] = useState();
    const navigation = useNavigate();
    const userId = localStorage.getItem("userID");
    const currYear = localStorage.getItem("form");
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;


    const handleNevigation = (data) => {
        if (data === "Purchased Electricity") {
            navigation("/new-assessment/scope-two/purchased-electricity");
        }
        else if (data === "Purchased Heat") {
            navigation("/new-assessment/scope-two/purchased-heat");
        }
        else if (data === "Purchased Refrigeration") {
            navigation("/new-assessment/scope-two/purchased-refrigeration");

        }
    }


    const getData = async () => {

        if (currYear !== null) {
            setloader(true)
            const result = await ViewScopeApi(userId, currYear, 2);
            setinnerData(result?.res.data)
            setloader(false)
        } else {
            setinnerData([])
        }

    }

    const handleHover = (data) => {


        if (data?.description) {
            const Addsupplier = <DescModal allData={data} />

            handleModalData(Addsupplier, "md");
        }
        else {
            toast.warning("No Description is There")
        }



    }
    React.useEffect(() => {
        getData()
    }, [])
    return (
        <div className='pt-12'>
            <Loader loader={loader} />
            <div className="grid grid-cols-1  xl:grid-cols-3  md:grid-cols-2 gap-4">
                {
                    innerData?.map((val) => (
                        <div onClick={() => handleNevigation(val.product)} className='flex items-center cursor-pointer labs justify-between'>
                            <div className=' flex items-center cursor-pointer' >
                                <img src={steamicon} alt="" />
                                <span className='px-6'>{val.product}</span>
                            </div>
                            <div onMouseEnter={() => handleHover(val)} >
                                <img src={info} alt="" />
                            </div>

                        </div>


                    ))
                }
            </div>
        </div>
    )
}

export default ScopeLabstwo