import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, GETSCOPEOPTIONDATA, deleteAsssessmentThree, getAsssessmentThree } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../../Common/DeleteConfirm";
import AutoSuggestion from "../../AutoSuggestion"
const SupplyLeaseRentTable = ({ months, YearData, CreatOn, manthFilter, setloader }) => {
    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([])
    const [editIndex, setEditIndex] = useState(-1);
    const [options, setOptions] = useState([]);
    const { plant } = useContext(AuthContext)
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { month: "", leased_name: "", energy_source_required: '', quantity: "", plant_type: plant, category: "Supplier Leased Assests", assessment_month: months, assessment_year: year_data, created_on: CreatOn }]);
    }

    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };
    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};
            if (!fuel.leased_name) {
                formIsValid = false;
                errors.leased_name = 'Please enter the  Name.';
            }
            else if (!isNaN(fuel.leased_name)) {
                formIsValid = false;
                errors.leased_name = ' Name must be a string.';
            }
            if (!fuel.energy_source_required) {
                formIsValid = false;
                errors.energy_source_required = 'Please enter the  Source.';
            }
            else if (!isNaN(fuel.energy_source_required)) {
                formIsValid = false;
                errors.energy_source_required = ' Source must be a string.';
            }
           

            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };

  
    const updateNameOfChemical = (index, energy_source_required) => {
        let filter_data = options?.filter((item) => item.id == energy_source_required);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].energy_source_required = filter_data[0]?.label;
      
        setFuelDetails(newFuelDetails)

    }

    const updateNameOfleased = (index, leased_name) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].leased_name = leased_name
        setFuelDetails(newFuelDetails)

    }



  

    const updateQuantitySold = (index, quantity_sold) => {
        let filter_data = options?.filter(item => item.label == fuelDetails[index].energy_source_required && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].quantity = quantity_sold
        newFuelDetails[index].emission_value = filter_data[0]?.value;
        newFuelDetails[index].scope_data = filter_data[0]?._value;
        setFuelDetails(newFuelDetails)
    }


    const SaveData = async () => {

        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    scopes3: fuelDetails,
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };
    const allAssessment = async (month) => {
        const year_data = localStorage.getItem("form")
        const result = await getAsssessmentThree(
            {
                assessment_year: year_data,
                plant_type: plant,
                category: "Supplier Leased Assests",
                assessment_month: manthFilter

            }

        );


        let filterData = result?.filter((item) => item.assessment_month === manthFilter);
        setFuelDetails(filterData)
    }

    const getSelectData = async () => {
        const result = await GETSCOPEOPTIONDATA(3, 17);
        const datas1 = result?.res?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));

        setOptions(datas1);

    }
    useEffect(() => {
        allAssessment()
        getSelectData()
    }, [months, plant])


    return (
        <div>
            <div className='plant-table mt-4' style={{ 'overflow-x': 'visible' }}>
                <table>
                    <thead>

                        <tr>
                            <th>S.No</th>
                            <th>Name of the Leased/Rented Asset</th>
                            <th>Source of Energy</th>
                         
                            <th>Quantity of Consumed Energy</th>
                 
                            <th></th>
                        </tr>


                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            const isQuantityDisabled = !isRowEdited || !fuel.energy_source_required || !fuel.transport_mode;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.leased_name}
                                            onChange={(event) =>
                                                updateNameOfleased(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.leased_name && <div className="error">{hasErrors.leased_name}</div>}
                                    </td>
                                 
                                    <td>

<AutoSuggestion data={options} prevalue={fuel.energy_source_required} updateNameOfChemical={updateNameOfChemical} index={index} disabled={!isRowEdited} />
{hasErrors && hasErrors.energy_source_required && <span className="error">{hasErrors.energy_source_required}</span>}
</td>
                                    <td>
                                        <input
                                            type='text'
                                            value={fuel.quantity}
                                            disabled={!isRowEdited}
                                            onChange={(event) =>
                                                updateQuantitySold(index, event.target.value)
                                            }
                                        />
                                          {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                        {hasErrors && hasErrors.quantity && <div className="error">{hasErrors.quantity}</div>}
                                    </td>

                               
                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SupplyLeaseRentTable;