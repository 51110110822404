import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn';
import CemaTable from '../../../Components/Common/Cematable';
import Descriptions from '../../../Components/Common/Descriptions';
import InnerHeader from '../../../Components/Common/InnerHeader';

const Cema = () => {                                                                                                                      
    
    return (
        <div >
            <InnerHeader title="Carbon Emission Mitigation Action" />
            <div className='px-16'>
                <Backbtn title="Carbon Emission Mitigation Action" />
                <Descriptions discrip="" />
                <CemaTable />
            </div>
        </div>

    )
}

export default Cema;