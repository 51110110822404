import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { addAsssementOne, AddAsssessmentthree, deleteAsssessmentOne, deleteAsssessmentThree, getAsssessmentOne, getAsssessmentThree, GETSCOPEOPTIONDATA } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../DeleteConfirm"
import AutoSuggestion from "../../AutoSuggestion"

const SubElectriConTable = ({ months, YearData, CreatOn, manthFilter, setloader }) => {
    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([]);
    const [options, setOptions] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const { plant } = useContext(AuthContext)
    const year_data = localStorage.getItem("form")

    const addRow = () => {
        setFuelDetails([...fuelDetails, { subsidiary_name: "", location: "", month: "", fuel_type: "", electricity_consumed: '', plant_type: plant, category: "Subsidiary Electricity Consumption", assessment_month: months, assessment_year: year_data, created_on: CreatOn, unit: '', emission_value: '', scope_data: '' }]);
    }

    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };

    const updateSubsidiaryName = (index, subsidiary_name) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].subsidiary_name = subsidiary_name
        setFuelDetails(newFuelDetails)

    }
    const updateLocationName = (index, location) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].location = location
        setFuelDetails(newFuelDetails)

    }

    const updateDateInvoice = (index, month) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].month = month
        setFuelDetails(newFuelDetails)

    }

    const updateNameOfChemical = (index, fuel_type) => {
        let filter_data = options?.filter((item) => item.id == fuel_type);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].fuel_type = filter_data[0]?.label;
        newFuelDetails[index].emission_value = filter_data[0]?.value;
        newFuelDetails[index].scope_data = filter_data[0]?._value;
        setFuelDetails(newFuelDetails)

    }



    const updateQuantity = (index, fuel_consumed_qty) => {
        let filter_data = options?.filter(item => item.label == fuelDetails[index].fuel_type && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].electricity_consumed = fuel_consumed_qty
        newFuelDetails[index].emission_value = filter_data[0]?.value;
        newFuelDetails[index].scope_data = filter_data[0]?._value;
        setFuelDetails(newFuelDetails)
    }


    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};

            if (!fuel.subsidiary_name) {
                formIsValid = false;
                errors.subsidiary_name = 'Please enter the Name.';
            }
            else if (!isNaN(fuel.subsidiary_name)) {
                formIsValid = false;
                errors.subsidiary_name = 'Name must be a string.';
            }

            if (!fuel.location) {
                formIsValid = false;
                errors.location = 'Please enter the Location.';
            }
            else if (!isNaN(fuel.location)) {
                formIsValid = false;
                errors.location = 'Location must be a string.';
            }
            if (!fuel.fuel_type) {
                formIsValid = false;
                errors.fuel_type = 'Please enter the fuel type.';
            }
            else if (!isNaN(fuel.fuel_type)) {
                formIsValid = false;
                errors.fuel_type = 'fuel type must be a string.';
            }
            if (!fuel.electricity_consumed) {
                formIsValid = false;
                errors.electricity_consumed = 'Please enter the Quantity.';
            }
            else if (fuel.electricity_consumed < 0) {
                formIsValid = false;
                errors.electricity_consumed = 'Quantity must be a greaterv than 0.';
            }
            else if (isNaN(fuel.electricity_consumed)) {
                formIsValid = false;
                errors.electricity_consumed = 'Quantity must be a Number.';
            }

            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };


    const SaveData = async () => {


        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    "scopes3": fuelDetails
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };
    const getSelectData = async () => {
        const result = await GETSCOPEOPTIONDATA(3, 20);
        const datas1 = result?.res?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_type,
        }));

        setOptions(datas1);

    }


    const allAssessment = async (month) => {
        setloader(false)
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree(
            {
                assessment_year: yaer_data,
                plant_type: plant,
                assessment_month: manthFilter,
                category: "Subsidiary Electricity Consumption",


            }

        );


        let filterData = result?.filter((item) => item.assessment_month === manthFilter);
        // console.log(filterData)
        setFuelDetails(filterData)
        setloader(false)
    }


    useEffect(() => {
        allAssessment(months)
        getSelectData();
    }, [months, plant])


    return (
        <div>
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name of Subsidiary</th>
                            <th>Location</th>
                            <th>Unit</th>
                            <th>Qunatity of Electricity  consumed</th>

                            {/* <th></th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const isQuantityDisabled = !isRowEdited || !fuel.fuel_type
                            const hasErrors = errors[index];
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.subsidiary_name}
                                            onChange={(event) =>
                                                updateSubsidiaryName(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.subsidiary_name && <span className="error">{hasErrors.subsidiary_name}</span>}
                                    </td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.location}
                                            onChange={(event) =>
                                                updateLocationName(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.location && <span className="error">{hasErrors.location}</span>}
                                    </td>
                                    <td>

                                        <AutoSuggestion data={options} prevalue={fuel.fuel_type} updateNameOfChemical={updateNameOfChemical} index={index} disabled={!isRowEdited} />
                                        {hasErrors && hasErrors.fuel_type && <span className="error">{hasErrors.fuel_type}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'

                                            value={fuel.electricity_consumed}
                                            onChange={(event) =>
                                                updateQuantity(index, event.target.value)
                                            }
                                        />

                                        {hasErrors && hasErrors.electricity_consumed && <span className="error">{hasErrors.electricity_consumed}</span>}
                                    </td>



                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SubElectriConTable;