import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { addAsssementOne, deleteAsssessmentOne, getAsssessmentOne, GETSCOPEOPTIONDATA } from '../../../Utils/services';
import AuthContext from '../../../Utils/auth-context';
import Select from 'react-select';
import DeleteConfirm from "../../Common/DeleteConfirm/index";
import AutoSuggestion from "../AutoSuggestion"
import Date from "../Date"


const PlantTable = ({ month, YearData, CreatOn, manthFilter, setloader }) => {
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState([]);
  const [data, setData] = useState();
  const [fuelDetails, setFuelDetails] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const { plant, authCtx } = useContext(AuthContext);

  const year_data = localStorage.getItem("form")
  const addRow = () => {
    setFuelDetails([...fuelDetails, { type_fuel: '', quantity: '', plant_type: plant, category: "Stationary Combustion", assessment_month: month, assessment_year: year_data, created_on: CreatOn, emission_value: 0, unit: '', scope_data: '' }]);
  };

  const handleDeleteConfirm = async (index, id) => {
    try {
      if (id) {
        await deleteAsssessmentOne(id);
      }
      const newFuelDetails = [...fuelDetails];
      newFuelDetails.splice(index, 1);
      setFuelDetails(newFuelDetails);
    } catch (error) {
      console.error(error);
    }
  };

  const updateType = (index, selectvalue) => {

    const type_fuel = selectvalue;
    let filter_data = options?.filter((item) => item.id == type_fuel);
    const newFuelDetails = [...fuelDetails];
    newFuelDetails[index].type_fuel = filter_data[0]?.name;
    newFuelDetails[index].unit = filter_data[0]?.unit;
    setFuelDetails(newFuelDetails);
  };

  const updateQuantity = (index, quantity) => {

    let filter_data_1 = options?.filter(item => item.name == fuelDetails[index].type_fuel && item.unit == fuelDetails[index].unit);
    const newFuelDetails = [...fuelDetails];
    newFuelDetails[index].quantity = quantity;
    newFuelDetails[index].emission_value = filter_data_1[0]?.value;
    newFuelDetails[index].scope_data = filter_data_1[0]?.scope_data;
    newFuelDetails[index].assessment_year = year_data;
    setFuelDetails(newFuelDetails);
  };

  const SaveData = async () => {
    if (editIndex != -1) {
      const filter_data = fuelDetails?.filter((res, index) => editIndex == index);
      const filter_data_1 = options?.filter(item =>
        item.name == filter_data[0]?.type_fuel && item.unit == filter_data[0]?.unit
      );
      const newFuelDetails = [...fuelDetails];
      newFuelDetails[editIndex].emission_value = filter_data_1[0]?.value;
      newFuelDetails[editIndex].scope_data = filter_data_1[0]?.scope_data;
      newFuelDetails[editIndex].assessment_year = year_data;
    }


    if (validateFields()) {
      try {

        const result = await addAsssementOne({
          scopes1: fuelDetails,
        });
        toast.success(result.message || 'Successfully add and update');
        allAssessment();
        setEditIndex(-1);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const getSelectData = async () => {

    try {
      setloader(true) 
      const result = await GETSCOPEOPTIONDATA(1,1);
      setOptions(result?.res);
    } catch (error) {
      // Handle the error here, such as logging it or displaying a message to the user
      console.error("Error fetching select data:", error);
    }
    finally {
      setloader(false)
    }
  }


  const allAssessment = async () => {
    setloader(true);
    try {
      let curryear = localStorage.getItem("form");
      const result = await getAsssessmentOne({
        assessment_month: manthFilter,
        plant_type: plant,
        category: "Stationary Combustion",
        assessment_year: curryear
      });

      if ((result && result?.length > 0)) {
        setFuelDetails(result);
      }
      else {
        setFuelDetails([])
      }

    } catch (error) {
      // Handle the error here, such as logging it or displaying a message to the user
      console.error("Error fetching assessment data:", error);
    } finally {
      // Set loader to false regardless of whether an error occurred or not
      setloader(false);
    }
  };

  const validateFields = () => {
    let formIsValid = true;
    const newErrors = fuelDetails.map((fuel) => {
      const errors = {};
      if (!fuel.type_fuel) {
        formIsValid = false;
        errors.type_fuel = 'Please select a fuel type.';
      }
      if (!fuel.quantity) {
        formIsValid = false;
        errors.quantity = 'Please enter the quantity.';
      } else if (isNaN(fuel.quantity)) {
        formIsValid = false;
        errors.quantity = 'Quantity must be a number.';
      } else if (fuel.quantity < 0) {
        formIsValid = false;
        errors.quantity = 'Quantity cannot be less than 0.';
      }
      return errors;
    });
    setErrors(newErrors);
    return formIsValid;
  };

  useEffect(() => {
    allAssessment();
    getSelectData();

  }, [plant, month]);

  React.useEffect(() => {
    try {
      setloader(true)
      const datas = options?.map((item, index) => ({
        id: item?.id,
        value: item?.name,
        label: item?.name,
        _unit: item?.unit,
      }));
      setData(datas);
    } catch (error) {
      // Handle the error here, such as logging it or displaying a message to the user
      console.error("Error processing options data:", error);
    }
    finally {
      setloader(false)
    }
  }, [options]);

  return (
    <div>
      <div className='plant-table mt-4'>
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Type of Fuel</th>
              <th>Quantity</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fuelDetails?.length > 0 && fuelDetails && fuelDetails?.map((fuel, index) => {
              const isRowEdited = (index === editIndex) || (!fuel.id);
              const hasErrors = errors[index];
              const isQuantityDisabled = (!isRowEdited) || (!fuel.type_fuel);
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <AutoSuggestion disabled={!isRowEdited} data={data} prevalue={fuel?.type_fuel} updateNameOfChemical={updateType} index={index} />
                    {hasErrors && hasErrors.type_fuel && <span className="error">{hasErrors.type_fuel}</span>}
                  </td>
                  <td>
                    <input
                      type='text'
                      value={fuel.quantity}
                      onChange={(event) => updateQuantity(index, event.target.value)}
                      disabled={isQuantityDisabled}
                    />

                    {fuel?.unit && <span className='mx-2'>{fuel?.unit}</span>}
                    {hasErrors && hasErrors.quantity && <span className="error">{hasErrors.quantity}</span>}
                  </td>
                  <td></td>
                  <td className='flex justify-end'>
                    {!isRowEdited && (
                      <button onClick={() => setEditIndex(index)}>
                        Edit
                      </button>
                    )}
                    {isRowEdited && (
                      <>
                        <button onClick={SaveData}>
                          Save
                        </button>
                      </>
                    )}
                    <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='w-full flex justify-end tfooter p-3'>
          <button className='addbtn' onClick={addRow}>
            Add Row
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlantTable;
