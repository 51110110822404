import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import Row_materialplant from '../../../Components/Common/ScopeThreeTables/Row-materials/Row_materialplant'

const RowMaterial = () => {
    return (
        <div>
            <InnerHeader innertitle="Purchased Raw Materials" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Purchased Raw Materials" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <Row_materialplant />
                {/* <StationaryPlantOne /> */}
            </div>
        </div>
    )
}

export default RowMaterial