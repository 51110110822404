import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logoIcon from "../../Assets/images/SignUp/actual.png";
import { PlantPost } from "../../Utils/services";
import { toast } from 'react-toastify';
import axios from 'axios';


const AddPlant = () => {
    const [rows, setRows] = useState([]); // initial state with empty array
    const [rowCount, setRowCount] = useState(0); // initial row count
    const navigate = useNavigate();
    const [user, setUser] = useState(localStorage.getItem("userID"));


    // function to add a new row
    const addRow = () => {
        const newRow = { id: rowCount + 1, name: '', address: '', operation_carried_out: '', plantinchage: "", ownership: "", reporting_year: "", userId: user ? user : localStorage.getItem("userID"), status: 1 };
        setRows([...rows, newRow]); // add new row to existing rows
        setRowCount(rowCount + 1); // increment row count
    };

    // function to delete a row by its ID
    const deleteRow = (id) => {
        const updatedRows = rows.filter(row => row.id !== id); // filter out the row with the given ID
        setRows(updatedRows); // update rows state
    };

    // function to handle input changes for each row
    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map(row => {
            if (row.id === id) {
                return { ...row, [field]: value }; // update field value for the row with the given ID
            }
            return row;
        });
        setRows(updatedRows); // update rows state
    };


    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem("token")
            if (rows != null) {
                const response = await axios.post('/plant', {
                    plants: rows,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 201) {
                    const result = response.data;

                    if (result.status == 200) {
                        toast.success(result.message || "Data Saved");
                        handleViewList();

                    }
                    else {
                        toast.error("Data Not Saved");
                    }
                } else {

                    console.error("HTTP error:", response);
                }
            }
        } catch (err) {
            console.log(err)
        }

    };



    const handleViewList = () => {

        navigate("/view-plant")
    }

    return (
        <div className='addplant'>
            <header className='p-8 flex justify-between'>
                <div>
                    <img src={logoIcon} alt="" />
                </div>
            </header>

            <section className='px-60 pt-20'>
                <h3 className='plant-details'>Plant Details</h3>

                <div className='plant-table mt-16'>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Operation Carried Out</th>
                                <th>Plant In-Charge</th>
                                <th>Ownership</th>
                                <th>FinancialYear</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map(row => (
                                <tr key={row.id}>
                                    {/* <td>{row.id}</td> */}
                                    <td>
                                        <input
                                            type="text"
                                            placeholder='Name'
                                            value={row.name}
                                            onChange={(e) => handleInputChange(row.id, 'name', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder='Address'
                                            value={row.address}
                                            onChange={(e) => handleInputChange(row.id, 'address', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder='Operation Carried Out'
                                            value={row.operation_carried_out}
                                            onChange={(e) => handleInputChange(row.id, 'operation_carried_out', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder='Plant In-Charge'
                                            value={row.plant_in_charge}
                                            onChange={(e) => handleInputChange(row.id, 'plant_in_charge', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <select className='p-2' value={row.ownership} onChange={(e) => handleInputChange(row.id, 'ownership', e.target.value)}
                                        >
                                            <option defaultValue="none">select ownership</option>
                                            <option value="Owned">Owned</option>
                                            <option value="Leased">Leased</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder='FinancialYear'
                                            value={row.reporting_year}
                                            onChange={(e) => handleInputChange(row.id, 'reporting_year', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => deleteRow(row.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>


                    </table>
                    <div className='w-full flex justify-end tfooter p-3'>
                        <button onClick={addRow} className="add-plant-btn mr-2" >Add Row</button>
                        <button onClick={handleSubmit} className="add-plant-btn" >Save</button>
                    </div>

                </div>


                <div className='pt-72'>
                    <button className='continue-btn' onClick={handleViewList}>Continue</button>
                </div>
                <div className='pt-20 '>
                    <p>*Plant details can be edited from within the dashboard inside ‘Settings’ tab.</p>
                </div>

            </section >
        </div >
    )
}

export default AddPlant