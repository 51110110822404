import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import EndofLifePlant from '../../../Components/Common/ScopeThreeTables/EndofLife/EndofLifePlant'

const EndofLife = () => {
    return (
        <div>
            <InnerHeader innertitle="End-of-Life Treatment of Sold Products" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="End-of-Life Treatment of Sold Products" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <EndofLifePlant />
            </div>
        </div>
    )
}

export default EndofLife