import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { GETSCOPEOPTIONDATA, addAsssementOne, deleteAsssessmentOne, getAsssessmentOne } from '../../../Utils/services';
import AuthContext from '../../../Utils/auth-context';
import AutoSuggestion from "../AutoSuggestion"
import DeleteConfirm from "../../Common/DeleteConfirm/index";
import Date from "../../Common/Date"


const ProcessEmissionTable = ({ month, YearData, CreatOn, manthFilter, setloader, monYear }) => {

    const [fuelDetails, setFuelDetails] = useState([])
    const [errors, setErrors] = useState({});
    const [data, setData] = useState();
    const [placeholder, setPlaceholder] = useState("");
    const [editIndex, setEditIndex] = useState(-1);
    const [options, setOptions] = useState([]);
    const { plant } = useContext(AuthContext)

    const addRow = () => {
        setFuelDetails([...fuelDetails, { date_invoice: "", chemical_name: "", quantity: '', plant_type: plant, category: "Process Emissions", assessment_month: month, assessment_year: YearData, created_on: CreatOn, unit: '', emission_value: '' }]);
    }


    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentOne(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };


    const updateType = (index, type_fuel) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].type_fuel = type_fuel
        setFuelDetails(newFuelDetails)

    }

    const updateDateInvoice = (index, date_invoice) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].date_invoice = date_invoice
        setFuelDetails(newFuelDetails)

    }

    const updateNameOfChemical = (index, chemical_name) => {
        let filter_data = options?.filter((item) => item.id === chemical_name);
        const newFuelDetails = [...fuelDetails];
        newFuelDetails[index].unit = filter_data[0]?.unit;
        newFuelDetails[index].chemical_name = filter_data[0]?.name
        setFuelDetails(newFuelDetails)


    }
    const getSelectData = async () => {
        const result = await GETSCOPEOPTIONDATA(1,4);
        setOptions(result?.res);

    }
    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};
            if (!fuel.date_invoice) {
                formIsValid = false;
                errors.date_invoice = 'Please enter the date of invoice.';
            }
            if (!fuel.chemical_name) {
                formIsValid = false;
                errors.chemical_name = 'Please enter the name of the chemical/gas.';
            }
            if (!fuel.quantity) {
                formIsValid = false;
                errors.quantity = 'Please enter the quantity.';
            } else if (isNaN(fuel.quantity)) {
                formIsValid = false;
                errors.quantity = 'Quantity must be a number.';
            }
            else if (fuel.quantity < 0) {
                formIsValid = false;
                errors.quantity = 'Quantity cannot be less than 0.';
            }
            return errors;
        });

        setErrors(newErrors);
        return formIsValid;
    };


    const updateQuantity = (index, quantity) => {
        let filter_data = options?.filter((item) => item?.name == fuelDetails[index]?.chemical_name);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].quantity = quantity;
        newFuelDetails[index].emission_value = filter_data[0]?.value;
        setFuelDetails(newFuelDetails)
    }

    const SaveData = async () => {
        if (editIndex != -1) {
            const filter_data = fuelDetails?.filter((res, index) => editIndex == index);
            const filter_data_1 = options?.filter((item) => item.name == filter_data[0].type_fuel);
            const newFuelDetails = [...fuelDetails];
            newFuelDetails[editIndex].emission_value = filter_data_1[0]?.value;
          }
        if (validateFields()) {
            try {
                setloader(true)
                const result = await addAsssementOne({
                    scopes1: fuelDetails,
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }

    };
    const allAssessment = async (month) => {
        setloader(true)
        const result = await getAsssessmentOne(
            {
                assessment_year: YearData,
                plant_type: plant,
                category: "Process Emissions"



            }

        );
        setloader(false)
        let filterData = result?.filter((item) => item.assessment_month === manthFilter);
        setFuelDetails(filterData)
    }
    React.useEffect(() => {
        const datas = options?.map((item) => ({
            id: item?.id,
            value: item?.name,
            label: item?.name,
        }));

        setData(datas);

    }, [options]);

    useEffect(() => {
        allAssessment(month)
        getSelectData();
    }, [month, plant])


    return (
        <div>
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Date of Invoice </th>
                            <th>Name of the Chemical/Gas</th>
                            <th>Quantity</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails?.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            const isQuantityDisabled = !isRowEdited || !fuel.chemical_name;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>

                                        <Date index={index} monYear={monYear} handle={updateDateInvoice} value={fuel.date_invoice} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.date_invoice && <span className="error">{hasErrors.date_invoice}</span>}
                                    </td>
                                    <td>

                                        <AutoSuggestion disabled={!isRowEdited} data={data} prevalue={fuel.chemical_name} updateNameOfChemical={updateNameOfChemical} index={index} />

                                        {hasErrors && hasErrors.chemical_name && <span className="error">{hasErrors.chemical_name}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            value={fuel.quantity} disabled={isQuantityDisabled}
                                            onChange={(event) =>
                                                updateQuantity(index, event.target.value)
                                            }
                                        />
                                        {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                        {hasErrors && hasErrors.quantity && <span className="error">{hasErrors.quantity}</span>}
                                    </td>

                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProcessEmissionTable