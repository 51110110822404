import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { AddAsssessmenttwo, GETSCOPEOPTIONDATA, getAsssessmentTwo } from "../../../../Utils/services";
import AuthContext from "../../../../Utils/auth-context";

function CoolingTableRow({ data, index, manthFilter, YearData, setloader }) {
    const [isEditing, setIsEditing] = useState(false);
    const [errors, setErrors] = useState({});
    const [rowData, setRowData] = useState({});
    const [unit, setUnit] = useState("");
    const { plant } = useContext(AuthContext);

    function handleInputChange(event) {
        const { name, value } = event.target;
        setRowData((prevData) => ({ ...prevData, [name]: value }));
    }

    const allAssessment = async () => {
        setRowData({})

        try {
            setloader(true)
            const result = await getAsssessmentTwo({
                assessment_year: YearData,
                category: "cooling",
                plant_type: plant,
            });
            const result2 = await GETSCOPEOPTIONDATA(2, 3);
            setUnit(result2?.res);
            setloader(false)

            if (result) {
                let filterData = result.filter((item) => item?.assessment_month === manthFilter);
                if (filterData.length === 0) {
                    setRowData({
                        id: data.id,
                        plant_type: plant,
                        assessment_month: data?.assessment_month,
                        source_name: "",
                        unit: "",
                        emission_value:  1,
                        consumed_power: "",
                        category: "cooling",
                        created_on: data.created_on,
                        layout_type: "2",
                    });
                } else {
                    setRowData(filterData[0]);
                }
            }
        } catch (error) {
            setloader(false)
            toast.error(error);
        }
    };

    useEffect(() => {
        allAssessment();
    }, [plant, manthFilter]);

    const validateFields = () => {
        let formIsValid = true;
        const newErrors = {};
        if (!rowData.source_name) {
            formIsValid = false;
            newErrors.source_name = "Please enter the Source";
        }

        else if (!isNaN(rowData.source_name)) {
            formIsValid = false;
            newErrors.source_name = "Source must be a String.";
        }
        if (!rowData.consumed_power) {
            formIsValid = false;
            newErrors.consumed_power = "Please enter the Consumed Power.";
        } else if (isNaN(rowData.consumed_power)) {
            formIsValid = false;
            newErrors.consumed_power = "Consumed Power must be a number.";
        }

        if (rowData.consumed_power < 0) {
            formIsValid = false;
            newErrors.consumed_power = "Consumed Power cannot be less than 0";
        }
        setErrors(newErrors);
        return formIsValid;
    };

    const handleSaveClick = async (id) => {
        try {
            if (validateFields()) {
                setloader(true)
                let filterData = unit?.filter((item) => item?.unit === rowData?.unit);
                const result = await AddAsssessmenttwo({
                    scopes2: [
                        {
                            "plant_type": plant,
                            "layout_type": "2",
                            "assessment_year": YearData,
                            "unit": rowData?.unit ? rowData?.unit : "p",
                            "emission_value": filterData[0]?.value ? filterData[0]?.value : 1,
                            "assessment_month": rowData?.assessment_month,
                            "source_name": rowData?.source_name,
                            "consumed_power": rowData?.consumed_power,
                            "category": "cooling",
                            "created_on": rowData.created_on,

                        },
                    ],
                });
                setloader(false)
                await allAssessment();
                setIsEditing(false);
                toast.success(result.message || "Data Successfully added and updated");
            }
        } catch (error) {
            toast.error(error);
        }
    };
 
    if (isEditing) {
        return (
            <tr>
                <td>{index + 1}</td>
                <td>
                    <input name="created_on" value={rowData.created_on} disabled />
                </td>
                <td>
                    <input name="source_name" value={rowData.source_name} onChange={handleInputChange} />
                    {errors.source_name && <span className="error">{errors.source_name}</span>}
                </td>

                <td>
                    <input name="consumed_power" value={rowData.consumed_power} onChange={handleInputChange} />
                    {errors.consumed_power && <span className="error" >{errors.consumed_power}</span>}
                </td>
                <td>
                    <button className="edit-btn-table" onClick={() => handleSaveClick(index + 1)}>
                        Save
                    </button>
                </td>
            </tr>
        );
    }

    return (
        <>
            {Object.keys(rowData).length !== 0 && (
                <tr>
                    <td className="p-2">{index + 1}</td>
                    <td className="p-2">
                        <input name="created_on" className="rowInput" value={rowData?.created_on} disabled />
                    </td>
                    <td className="p-2">
                        <input className="rowInput" name="source_name" value={rowData?.source_name} disabled />
                    </td>

                    <td className="p-2">
                        <input className="rowInput" name="consumed_power" value={rowData?.consumed_power} disabled />
                    </td>
                    <td className="p-2">
                        <button className="edit-btn-table" onClick={() => setIsEditing(true)}>
                            Edit
                        </button>
                    </td>
                </tr>
            )}
        </>
    );
}

export default CoolingTableRow;
