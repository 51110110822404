import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import ProcessSoldProduct from '../../../Components/Common/ScopeThreeTables/ProcessSoldProduct/ProcessSoldProductPlant'

const ProcessSold = () => {
    return (
        <div>
            <InnerHeader innertitle="Processing of Sold Products" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Processing of Sold Products" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <ProcessSoldProduct />
            </div>
        </div>
    )
}

export default ProcessSold