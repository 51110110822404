import React, { useEffect } from 'react';
import thankgif from "./../../Assets/images/SignUp/thnak.gif";
import { useNavigate } from 'react-router-dom';


function Conformation() {
  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");

    // Redirect to the "Add Plant" page after 3000 milliseconds (3 seconds)
    const timeoutId = setTimeout(() => {
      if (token) {
        // Assuming "/add-plant" is the route for the "Add Plant" page
        navigate('/addplant');
      } else {

        navigate('/login');
      }
    }, 3000);

    // Clear the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(timeoutId);
  }, [navigate]);
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center'>
        <img className='mx-auto' src={thankgif} alt='' />
        <h6 className='mt-4'>Thank you! Your Payment has Successfully Completed.</h6>
      </div>
    </div>

  )
}

export default Conformation