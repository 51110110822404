import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, GETSCOPEOPTIONDATA, deleteAsssessmentThree, getAsssessmentThree } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../DeleteConfirm"
import AutoSuggestion from "../../AutoSuggestion";

const ProcessSoldProductTable = ({ months, YearData, CreatOn, manthFilter, setloader }) => {
    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [options, setOptions] = useState([]);
    const { plant } = useContext(AuthContext);
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { sold_product_name: "", quantity_sold: "", fuel_consumed_qty: "", average_runtime: "", plant_type: plant, category: "End-of-Life Treatment of Sold Products", assessment_month: months, assessment_year: year_data, created_on: CreatOn, emission_value: '' }]);
    }

    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };


    const updateDateInvoice = (index, month) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].month = month
        setFuelDetails(newFuelDetails)

    }

    const updateNameOfSoldPorduct = (index, sold_product_name) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].sold_product_name = sold_product_name
        setFuelDetails(newFuelDetails)

    }



    const updateQuantitySold = (index, quantity_sold) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].quantity_sold = quantity_sold
        setFuelDetails(newFuelDetails)
    }
    const updateEnergySoldProduct = (index, fuel_consumed_qty) => {
        let filter_data = options?.filter(item => item.label == fuelDetails[index].fuel_type && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].fuel_consumed_qty = fuel_consumed_qty
        newFuelDetails[index].emission_value = filter_data[0]?.value
        newFuelDetails[index].scope_data = filter_data[0]?._value
        setFuelDetails(newFuelDetails)
    }

    const updateFuelType = (index, fuel_type) => {
        let filter_data = options?.filter((item) => item.id === fuel_type);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].fuel_type = filter_data[0]?.label
   
        setFuelDetails(newFuelDetails)
    }

    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};  

            if (!fuel.sold_product_name) {
                formIsValid = false;
                errors.sold_product_name = 'Please  enter the sold product name';
            }
            else if (!isNaN(fuel.sold_product_name)) {
                formIsValid = false;
                errors.sold_product_name = 'sold product name must be a string.';
            }

            if (!fuel.fuel_consumed_qty && fuel.fuel_consumed_qty !== 0) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Please enter the Weight of Product.';
            } else if (isNaN(fuel.fuel_consumed_qty)) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Weight of Product must be a number.';
            }
            else if (fuel.fuel_consumed_qty < 0) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Weight of Product must be a greater than - 1';
            }


            if (!fuel.fuel_type) {
                formIsValid = false;
                errors.fuel_type = 'Please  select the 	Type of End of Life Treatment ';
            }

            if (!fuel.quantity_sold && fuel.quantity_sold !== 0) {
                formIsValid = false;
                errors.quantity_sold = 'Please enter the Quantity.';
            } else if (isNaN(fuel.quantity_sold)) {
                formIsValid = false;
                errors.quantity_sold = 'Quantity must be a number.';
            }
            else if (fuel.quantity_sold < 0) {
                formIsValid = false;
                errors.quantity_sold = 'Quantity must be a greater than - 1';
            }
            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };



    const SaveData = async () => {

        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    scopes3: fuelDetails,
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };


    const allAssessment = async (month) => {
        setloader(true);
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree(
            {
                assessment_year: yaer_data,
                plant_type: plant,
                category: "End-of-Life Treatment of Sold Products",
                assessment_month: manthFilter,
            }

        );


        let filterData = result?.filter((item) => item.assessment_month === manthFilter);
        // console.log(filterData)
        setFuelDetails(filterData)
        setloader(false)
    }

    const getSelectData = async () => {
        const result = await GETSCOPEOPTIONDATA(3, 26);
        const datas = result?.res?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));


        setOptions(datas)

    }
    useEffect(() => {
        allAssessment(months)
        getSelectData();
    }, [months, plant])


    return (
        <div >
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>

                            <th>Name of Sold Product</th>
                            <th>Quantity Sold</th>
                      
                            <th>Type of End of Life Treatment</th>
                            <th>Weight of Product (in kg)</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.sold_product_name}
                                            onChange={(event) =>
                                                updateNameOfSoldPorduct(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.sold_product_name && <span className="error">{hasErrors.sold_product_name}</span>}
                                    </td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.quantity_sold}
                                            onChange={(event) =>
                                                updateQuantitySold(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.quantity_sold && <span className="error">{hasErrors.quantity_sold}</span>}
                                    </td>

                                 

                                    <td>
                                        <AutoSuggestion data={options} prevalue={fuel.fuel_type} updateNameOfChemical={updateFuelType} index={index} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.fuel_type && <span className="error">{hasErrors.fuel_type}</span>}
                                    </td>


                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.fuel_consumed_qty}
                                            onChange={(event) =>
                                                updateEnergySoldProduct(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.fuel_consumed_qty && <span className="error">{hasErrors.fuel_consumed_qty}</span>}
                                    </td>


                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProcessSoldProductTable;