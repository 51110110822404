import React from 'react';
import Select from 'react-select';

export default function Index({ data, updateNameOfChemical, index, prevalue, disabled, setField }) {


    const onTextChanged = (res) => {
        updateNameOfChemical(index, res?.id);
        {
            setField && setField(res?.id)
        }

    };

    const formattedPrevalue = prevalue ? { value: prevalue, label: prevalue } : null;

    return (
        <>
            <div className='autosuggest'>
                <Select
                    options={data}
                    onChange={onTextChanged}
                    isDisabled={disabled}
                    value={formattedPrevalue}
                // isClearable={true}
                />
            </div>

        </>
    );
}