import React from 'react'
import ChemicalPlant from './PlantTables/ChemicalPlant'
import ElectricityPlantTable from './ScopTwoPlantTable/ElectricityPlantTable'

const EctricityPlant = () => {
    let curryear = localStorage.getItem("form");
    return (
        <div className='pt-2'>
            <p className='profile-texts'>Donnelly-Wisozk Die cast manufacturing unit</p>
            <div className='pt-4'>
                <p className='ass-year'>FinancialYear :{curryear}</p>
            </div>
            {/* <p className='pt-4'>April 2020</p> */}
            <ElectricityPlantTable />
            {/* <ChemicalPlant month="April 2020" />
            <p className='pt-4'>May 2020</p>
            <ChemicalPlant month="May 2020" />
            <p className='pt-4'>June 2020</p>
            <ChemicalPlant month="June 2020" /> */}
        </div>
    )
}

export default EctricityPlant