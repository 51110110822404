import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoIcon from "../../Assets/images/SignUp/actual.png";
import { PlantView } from "../../Utils/services";


const ShowPlant = () => {
    const [data, setData] = useState([]);
     const navigate = useNavigate();



    const asingment = async () => {
        const result = await PlantView();
        setData(result?.res)
   
    }
    useEffect(() => {
        asingment()
    }, [])


    const handleClick = () => {

        navigate("/")
    }
    return (
        <div className='addplant'>
            <header className='p-8 flex justify-between'>
                <div>
                    <img src={logoIcon} alt="" />
                </div>
            </header>

            <section className='px-60 pt-20'>
                <h3 className='plant-details'>Plant Details</h3>

                <div className='plant-table mt-16'>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Operation Carried Out</th>
                                <th>Plant In-Charge</th>
                                <th>Ownership</th>
                                <th>FinancialYear</th>
                            </tr>
                        </thead>
                        <tbody>


                            {
                                data && data.map((val) => {
                                    return (
                                        <>

                                            <tr key={val?.id}>
                                                <td>
                                                    {
                                                        val?.name
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val?.address
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val?.operation_carried_out
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val?.plant_in_charge
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val?.ownership
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val?.reporting_year

                                                    }
                                                </td>
                                            </tr>

                                        </>
                                    );
                                })
                            }
                            {/* <td>{row.id}</td> */}




                        </tbody>


                    </table>
                    <div className='w-full flex justify-end tfooter p-3'>
                        {/* <button onClick={addRow} className="add-plant-btn mr-2" >Add Row</button> */}
                        {/* <button className="add-plant-btn" >Save</button> */}
                    </div>

                </div>


                <div className='pt-72'>
                    <button onClick={handleClick} className='continue-btn' >Continue</button>
                </div>
                <div className='pt-20 '>
                    <p>*Plant details can be edited from within the dashboard inside ‘Settings’ tab.</p>
                </div>

            </section >
        </div >
    )
}

export default ShowPlant