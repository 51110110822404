import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import MobileCombustionPlant from '../../../Components/Common/MobileCombustionPlant'
import StationaryPlantOne from '../../../Components/Common/StationaryPlantOne'

const MobileCombustion = () => {


    return (
        <div>
            <InnerHeader   innertitle="Mobile Combustion" title="Scope 1"/>
            <div className='px-16'>
                <Backbtn title="Mobile Combustion" />
                <Descriptions discrip="" />
                <InnerTabe />
                <MobileCombustionPlant />

            </div>
        </div>
    )
}

export default MobileCombustion;