import React, { useState, useEffect } from 'react'
import { AssessmentCema, GETSAVEDCEMADATA, CemaEdit } from '../../Utils/services'
import CemaRow from './CemaRow';
import { toast } from "react-toastify";

const CemaTable = () => {
    const [datas, setdatas] = useState([]);
    const [allData, setallData] = useState([]);
    const [save, setSave] = useState(false)
    const [isEditing, setIsEditing] = useState(false);

    const getAssessmentTwoApi = async () => {
        const result = await AssessmentCema();
        const getdata = await GETSAVEDCEMADATA();

        if (getdata?.length == 0) {
            const arrayOfObjects = result.res?.map(item => ({ id: item.id, carbon_emission: item.name, quantity: 0, assessment_year: "2023-2024", category: "cema" }));
            setdatas(arrayOfObjects);
        }
        else {
            setdatas(getdata);
        }

    }
    const handleSaveClick = async () => {
    
       
        try {
            
            if (!save) {
                const result = await CemaEdit({
                    cema: datas,
                });
                setIsEditing(false);
                toast.success(result.message || "Successfully added and updated");
            }

        } catch (error) {
            toast.error("Failed to save the data");
        }
    };


    useEffect(() => {
        getAssessmentTwoApi()
    }, [])

    return (
        <div>
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th >S.No.</th>
                            <th >Carbon Emission Mitigation Actions</th>
                            <th>Quantity</th>

                            {/* <th></th> */}
                        </tr>
                    </thead>
                    <div>

                    </div>
                    <tbody>
                        {datas?.map((item, index) => (
                            <CemaRow key={item.id} index={index} item={item} datas={datas} isEditing={isEditing} setdatas={setdatas} setSave={setSave} />
                        ))}

                    </tbody>


                </table>
                <div className='back_cema'>
                    <div className='cema_btn'>
                        <div>

                        </div>
                        <div>
                            {
                                !isEditing ? (<button class="cemaa_inbtn mr-2" onClick={() => setIsEditing(true)}>Edit</button>) :
                                    (<button class="cemaa_inbtn mr-2" onClick={handleSaveClick}>Save</button>)
                            }


                        </div>
                    </div>
                </div>

            </div>

        </div>

    )
}

export default CemaTable