import React, { useContext } from 'react'
import Arrow from "../../Assets/images/icons/arrow_back.svg"
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../Utils/auth-context';

const Backbtn = ({ title, handleBack }) => {
    const navigate = useNavigate();
    const { toogle, handle } = useContext(AuthContext)
    return (
        <div className=' scope-wraper pt-2 pb-4'>
            <div className="flex justify-between items-center">
                <div className='go_back flex items-center'>
                    <button className='back_btn' onClick={() => navigate(-1)}><img src={Arrow} alt="" /></button>
                    <h3 className='px-4'> {title}</h3>
                </div>
             
                <div id='hamburger-1'   
                className={`hamburger ${toogle ? 'is-active hidden' : ''}`}
                onClick={handle}
            >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>

          
                </div>
            </div>
        </div>
    )
}

export default Backbtn