import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, GETSCOPEOPTIONDATA, deleteAsssessmentThree, getAsssessmentThree } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../DeleteConfirm"
import AutoSuggestion from "../../AutoSuggestion";

const SoldProductTable = ({ months, YearData, CreatOn, manthFilter, setloader }) => {
    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([]);
    const [editIndex, setEditIndex] = useState(-1); 
    const [options, setOptions] = useState([]);
    const { plant } = useContext(AuthContext);
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { month: "", sold_product_name: "", quantity_sold: "", energy_source_required: "", average_runtime: "", product_lifespan: "", plant_type: plant, category: "Use of Sold Products", assessment_month: months, assessment_year: year_data, created_on: CreatOn, amount_energy_required: "", emission_value: '' }]);
    }

    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };


    const updateDateInvoice = (index, month) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].month = month
        setFuelDetails(newFuelDetails)

    }

    const updateNameOfSoldPorduct = (index, sold_product_name) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].sold_product_name = sold_product_name
        setFuelDetails(newFuelDetails)

    }



    const updateQuantitySold = (index, quantity_sold) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].quantity_sold = quantity_sold
        setFuelDetails(newFuelDetails)
    }
    const updateEnergySoldProduct = (index, energy_source_required) => {

        let filter_data = options?.filter((item) => item.id == energy_source_required);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].energy_source_required = filter_data[0]?.label
  
        setFuelDetails(newFuelDetails)
    }
    const updateEnergy = (index, amount_energy_required) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].amount_energy_required = amount_energy_required
        setFuelDetails(newFuelDetails)
    }


    const updateAverageRunTime = (index, average_runtime) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].average_runtime = average_runtime
        setFuelDetails(newFuelDetails)
    }

    const updatePrdouctlifeSpan = (index, product_lifespan) => {
        let filter_data = options?.filter(item => item.label == fuelDetails[index].energy_source_required && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].product_lifespan = product_lifespan
        newFuelDetails[index].emission_value = filter_data[0]?.value;
        newFuelDetails[index].scope_data = filter_data[0]?._value;
        setFuelDetails(newFuelDetails)
    }
    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};

            if (!fuel.sold_product_name) {
                formIsValid = false;
                errors.sold_product_name = 'Please  enter the sold product name';
            }
            else if (!isNaN(fuel.sold_product_name)) {
                formIsValid = false;
                errors.sold_product_name = 'sold product name must be a string.';
            }
            if (!fuel.energy_source_required) {
                formIsValid = false;
                errors.energy_source_required = 'Please  enter the Source of energy';
            }
            else if (!isNaN(fuel.energy_source_required)) {
                formIsValid = false;
                errors.energy_source_required = 'Source of energy must be a string.';
            }

            if (!fuel.amount_energy_required && fuel.amount_energy_required !== 0) {
                formIsValid = false;
                errors.amount_energy_required = 'Please enter the Energy.';
            } else if (isNaN(fuel.amount_energy_required)) {
                formIsValid = false;
                errors.amount_energy_required = 'Energy  must be a number.';
            }
            else if (fuel.amount_energy_required < 0) {
                formIsValid = false;
                errors.amount_energy_required = 'Energy  must be a greater than - 1.';
            }


            if (!fuel.product_lifespan && fuel.product_lifespan !== 0) {
                formIsValid = false;
                errors.product_lifespan = 'Please enter the Lifespan.';
            } else if (isNaN(fuel.product_lifespan)) {
                formIsValid = false;
                errors.product_lifespan = 'Lifespan must be a number.';
            }
            else if (fuel.product_lifespan < 0) {
                formIsValid = false;
                errors.product_lifespan = 'Lifespan must be a greater than - 1';
            }

            if (!fuel.average_runtime && fuel.average_runtime !== 0) {
                formIsValid = false;
                errors.average_runtime = 'Please enter the Average Run time.';
            } else if (isNaN(fuel.average_runtime)) {
                formIsValid = false;
                errors.average_runtime = 'Average Run time must be a number.';
            }
            else if (fuel.average_runtime < 0) {
                formIsValid = false;
                errors.average_runtime = 'Average Run time must be a greater than - 1';
            }

            if (!fuel.quantity_sold && fuel.quantity_sold !== 0) {
                formIsValid = false;
                errors.quantity_sold = 'Please enter the Quantity.';
            } else if (isNaN(fuel.quantity_sold)) {
                formIsValid = false;
                errors.quantity_sold = 'Quantity must be a number.';
            }
            else if (fuel.quantity_sold < 0) {
                formIsValid = false;
                errors.quantity_sold = 'Quantity must be a greater than - 1';
            }
            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };



    const SaveData = async () => {

        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    scopes3: fuelDetails,
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };


    const allAssessment = async (month) => {
        setloader(true)
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree(
            {
                assessment_year: yaer_data,
                plant_type: plant,
                category: "Use of Sold Products",
                assessment_month: manthFilter,
            }

        );


        let filterData = result?.filter((item) => item.assessment_month === manthFilter);
        // console.log(filterData)
        setFuelDetails(filterData)
        setloader(false)
    }
    const getSelectData = async () => {
        const result = await GETSCOPEOPTIONDATA(3, 25);
        const datas1 = result?.res?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));

        setOptions(datas1);

    }

    useEffect(() => {
        allAssessment(months);
        getSelectData();
    }, [months, plant])


    return (
        <div >
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>

                            <th>Name of Sold Product</th>
                            <th>Quantity Sold</th>
                            <th>Source of Energy Required</th>
                            <th>Amount of Energy Required</th>
                            <th>Average Runtime per Day</th>
                            <th>Lifespan of the Product</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.sold_product_name}
                                            onChange={(event) =>
                                                updateNameOfSoldPorduct(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.sold_product_name && <span className="error">{hasErrors.sold_product_name}</span>}
                                    </td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.quantity_sold}
                                            onChange={(event) =>
                                                updateQuantitySold(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.quantity_sold && <span className="error">{hasErrors.quantity_sold}</span>}
                                    </td>
                                    <td>

                                        <AutoSuggestion data={options} prevalue={fuel.energy_source_required} updateNameOfChemical={updateEnergySoldProduct} index={index} disabled={!isRowEdited} />
                                        {hasErrors && hasErrors.energy_source_required && <span className="error">{hasErrors.energy_source_required}</span>}
                                    </td>


                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.amount_energy_required}
                                            onChange={(event) =>
                                                updateEnergy(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.amount_energy_required && <span className="error">{hasErrors.amount_energy_required}</span>}
                                        {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            value={fuel.average_runtime}
                                            disabled={!isRowEdited}
                                            onChange={(event) =>
                                                updateAverageRunTime(index, event.target.value)
                                            }
                                        />
                                     
                                        {hasErrors && hasErrors.average_runtime && <span className="error">{hasErrors.average_runtime}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.product_lifespan}
                                            onChange={(event) =>
                                                updatePrdouctlifeSpan(index, event.target.value)
                                            }
                                        />

                                        {hasErrors && hasErrors.product_lifespan && <span className="error">{hasErrors.product_lifespan}</span>}
                                    </td>


                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SoldProductTable;