import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, GETSCOPEOPTIONDATA, deleteAsssessmentThree, getAsssessmentThree, SCOPE3DATAPi, GETCATEGORYAPi, POSTEMISSIONAPi } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../../Common/DeleteConfirm";
import AutoSuggestion from "../../AutoSuggestion"

const Employeetable = ({ month, YearData, CreatOn, manthFilter, setloader }) => {
    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [gettrnsport, setGettrnsport] = useState();
    const [options, setOptions] = useState({ option1: [], option2: [] });
    const { plant } = useContext(AuthContext);
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { month: "", employee_name: "", arrival_place: "", transport_mode: "", fuel_type: "", distance_travelled_day: '', working_days: "", plant_type: plant, category: "Employee Commuting", assessment_month: month, assessment_year: year_data, created_on: CreatOn, unit: '', emission_value: '' }]);
    }
    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};


            if (!fuel.employee_name) {
                formIsValid = false;
                errors.employee_name = 'Please enter the Name.';
            }
            else if (!isNaN(fuel.employee_name)) {
                formIsValid = false;
                errors.employee_name = 'Name must be a string.';
            }


            if (!fuel.transport_mode) {
                formIsValid = false;
                errors.transport_mode = 'Please enter the Transport mode.';
            }
            else if (!isNaN(fuel.transport_mode)) {
                formIsValid = false;
                errors.transport_mode = 'Transport mode must be a string.';
            }
            if (fuel.transport_mode != "Walk" && fuel.transport_mode != "Cycle") {
                if (!fuel.fuel_type) {
                    formIsValid = false;
                    errors.fuel_type = 'Please enter the Fuel type.';
                }
                else if (!isNaN(fuel.fuel_type)) {
                    formIsValid = false;
                    errors.fuel_type = 'Fuel type must be a string.';
                }
            }
            
            if (!fuel.distance_travelled_day && fuel.distance_travelled_day !== 0) {
                formIsValid = false;
                errors.distance_travelled_day = 'Please enter the Distance.';
            }
            else if (isNaN(fuel.distance_travelled_day)) {
                formIsValid = false;
                errors.distance_travelled_day = 'Distance must be a Number.';
            }
            else if (fuel.distance_travelled_day < 0) {
                formIsValid = false;
                errors.distance_travelled_day = 'Distance must be a positive Number';
            }
            if (!fuel.working_days && fuel.working_days !== 0) {
                formIsValid = false;
                errors.working_days = 'Please enter the working days.';
            } else if (isNaN(fuel.working_days)) {
                formIsValid = false;
                errors.working_days = 'working days must be a number.';
            }
            else if (isNaN(fuel.working_days) || fuel.working_days < 0 || fuel.working_days > 31) {
                formIsValid = false;
                errors.working_days = 'working days must be a valid number between 0 and 31';
            }
            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };
  
    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };


    const updateDate = (index, month) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].month = month
        setFuelDetails(newFuelDetails)

    }

    const updateEmployeeName = (index, employee_name) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].employee_name = employee_name
        setFuelDetails(newFuelDetails)

    }
    const getSelectData = async () => {
        const category_data = await GETCATEGORYAPi();
        const result = await SCOPE3DATAPi(3, 14);
        const datas1 = result?.res?.map((item) => ({
            id: item?.category_name,
            label: item?.category_name,
            unit: item?.category_name,
            value: item?.category_name,
            _value: item?.category_name,
        }));


        setOptions((prevState) => ({
            ...prevState,
            option1: datas1,

        }));

    }
    const getFuel = async () => {
        const data =
        {
            'sub_category': gettrnsport,
            'category': 14
        }
        const category_data = await POSTEMISSIONAPi(data);
        const datas1 = category_data?.data?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));


        setOptions((prevState) => ({
            ...prevState,
            option2: datas1,

        }));

    }
    useEffect(() => {

        getFuel();
    }, [gettrnsport]);



    const updateArival = (index, arrival_place) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].arrival_place = arrival_place
        setFuelDetails(newFuelDetails)

    }

    const updateModetransport = (index, transport_mode) => {
        let filter_data = options?.option1.filter((item) => item.id === transport_mode);
        const newFuelDetails = [...fuelDetails];
       
        newFuelDetails[index].transport_mode = filter_data[0]?.label
        setFuelDetails(newFuelDetails)

    }
    const updateFueltype = (index, fuel_type) => {
        let filter_data = options?.option2.filter((item) => item.id === fuel_type);
        const newFuelDetails = [...fuelDetails];
        newFuelDetails[index].fuel_type = filter_data[0]?.label
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].scope_data = filter_data[0]?._value
        setFuelDetails(newFuelDetails)
    }


    const updateDistance = (index, distance_travelled_day) => {
        let filter_data = options?.option2?.filter(item => item.label == fuelDetails[index].fuel_type && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].distance_travelled_day = distance_travelled_day;
        newFuelDetails[index].emission_value = filter_data[0]?.value
        setFuelDetails(newFuelDetails)
    }

    const updateWorkingDays = (index, working_days) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].working_days = working_days
        setFuelDetails(newFuelDetails)
    }



    const SaveData = async () => {


        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    "scopes3": fuelDetails
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };

    const allAssessment = async () => {
        setloader(true)
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree(
            {
                assessment_year: yaer_data,
                plant_type: plant,
                category: "Employee Commuting",
                assessment_month: manthFilter,
                

            }

        );
        setloader(false)
        let filterData = result?.filter((item) => item.assessment_month === manthFilter);
        setFuelDetails(filterData)
    }


    useEffect(() => {
        allAssessment();
        getSelectData()
    }, [month, plant])

   
    return (
        <div >
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name of the Employee</th>
                            <th>Mode of Transport</th>
                            <th>Type of Fuel</th>
                            <th>Distance Travelled per day</th>
                            <th>No. of Working Days</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {
                     
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            const isQuantityDisabled = !isRowEdited || !fuel.transport_mode;
                            const isFuelDisabled = !isRowEdited || (fuel && fuel.transport_mode === "Walk")
                                || (fuel && fuel.transport_mode === "Cycle");
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel?.employee_name}
                                            onChange={(event) =>
                                                updateEmployeeName(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.employee_name && <span className="error">{hasErrors.employee_name}</span>}
                                    </td>

                                    <td>

                                        <AutoSuggestion data={options.option1} prevalue={fuel.transport_mode} updateNameOfChemical={updateModetransport} index={index} disabled={!isRowEdited}  setField={setGettrnsport}/>

                                        {hasErrors && hasErrors.transport_mode && <span className="error">{hasErrors.transport_mode}</span>}
                                    </td>
                                    <td>
                                        <AutoSuggestion data={options.option2} prevalue={fuel.fuel_type} updateNameOfChemical={updateFueltype} index={index} disabled={isFuelDisabled} />

                                        {hasErrors && hasErrors.fuel_type && <span className="error">{hasErrors.fuel_type}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            disabled={isQuantityDisabled}
                                            value={fuel.distance_travelled_day}
                                            onChange={(event) =>
                                                updateDistance(index, event.target.value)
                                            }
                                        />
                                        {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                        {hasErrors && hasErrors.distance_travelled_day && <span className="error">{hasErrors.distance_travelled_day}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.working_days}
                                            onChange={(event) =>
                                                updateWorkingDays(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.working_days && <span className="error">{hasErrors.working_days}</span>}
                                    </td>


                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Employeetable;