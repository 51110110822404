import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import GoodServicesPlant from '../../../Components/Common/ScopeThreeTables/GoodAndServices/GoodServicesPlant'

const GoodsandServices = () => {
    return (
        <div>
            <InnerHeader   innertitle="Puchased Capital Goods" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Puchased Capital Goods" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <GoodServicesPlant />
            </div>
        </div>
    )
}

export default GoodsandServices