import React, { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../Context';
import CommonModal from '../CommonModal';
import MainWrapper from './MainWrapper'
import Sidenav from './Sidenav'
import AuthContext from '../../Utils/auth-context';





const Layouts = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState("");
    const [modalSize, setModalSize] = useState("");
    const [toggle2, setToggle2] = useState(true)
    const [frontModal, setfrontModal] = useState(false);
    const [data, setData] = useState({});
    const { handle, toogle, setToogle } = useContext(AuthContext)


    const handleModalData = (data, size = "xl", data2) => {
        setModalData(data);
        setModalSize(size);
        setIsModalOpen(true);
        setfrontModal(data2);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handletoggle = () => {
        if (toggle2) {
            setToogle(!toogle)
        }

    }
    // <-----------code to get the current screen size and chnage the toggle of sidebar------------>
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {


        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, []);


    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    // const width = window.innerWidth;
    useEffect(() => {
        if (windowSize.innerWidth < 963) {
            setToogle(false)
            setToggle2(true)
        }
        else if (windowSize.innerWidth > 963) {
            setToogle(true)
            setToggle2(false)
        }

    }, [windowSize.innerWidth]);

    return (
        <>

            <ModalContext.Provider
                value={{
                    handleModalData,
                    closeModal,
                    setData,
                    data,
                    isModalOpen,
                    modalData,
                    frontModal
                }}
            >
                <div className="Layout flex ">

                    <div className={toogle ? 'side-nav  p-4 mt-2 ml-2 mb-2' : 'side-nav1 p-4 mt-2 ml-2 mb-2'}>
                        <Sidenav toggle={toogle} handletoggle={handletoggle} />

                    </div>
                    <div className="page-container">

                        <MainWrapper />

                    </div>
                    {
                        <CommonModal
                            handleModalData={handleModalData}
                            frontModal={frontModal}
                            isModalOpen={isModalOpen}
                            modalData={modalData}
                            modalSize={modalSize}
                            closeModal={closeModal}
                        />
                    }
                </div>



            </ModalContext.Provider>
        </>
    )
}

export default Layouts