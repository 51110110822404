import React, { useState, useEffect } from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import StationaryPlantOne from '../../../Components/Common/StationaryPlantOne'
import CustomTabs2 from '../../../Components/Common/CustomTabs2'
import { PlantView } from "../../../Utils/services";

const StationayCombustion = () => {


    return (
        <div>
            <InnerHeader title="Scope 1" innertitle="Stationary Combustion" />
            <div className='px-16'>
                <Backbtn title="Stationary Combustion" />
                <Descriptions discrip="" />
                <InnerTabe />
                <StationaryPlantOne />
            </div>
        </div>
    )
}

export default StationayCombustion