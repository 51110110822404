import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import SfcPlant from '../../../Components/Common/ScopeThreeTables/SfcPlant/SfcPlant'
import SubFuelConPlant from '../../../Components/Common/ScopeThreeTables/subFuelCons/SubFuelConPlant'

const SubsidiaryFuelConsumption = () => {
    return (
        <div>
            <InnerHeader  innertitle="Subsidiary Fuel Consumption" title="Scope 3"   />
            <div className='px-16'>
                <Backbtn title="Subsidiary Fuel Consumption" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <SubFuelConPlant />
            </div>
        </div>
    )
}

export default SubsidiaryFuelConsumption