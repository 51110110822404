import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import SubsElectriConPlant from '../../../Components/Common/ScopeThreeTables/SubsElectricConsm/SubsElectriConPlant'

const SubsidiaryElectricConsm = () => {
    return (
        <div>
            <InnerHeader innertitle="Subsidiary Electricity Consumption" title="Scope 3"  />
            <div className='px-16'>
                <Backbtn title="Subsidiary Electricity Consumption" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <SubsElectriConPlant />
            </div>
        </div>
    )
}

export default SubsidiaryElectricConsm