import React, { useContext, useEffect, useState } from 'react';
import { EmailVerficationAPI, resendOTPAPI } from "../../Utils/services";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../Utils/auth-context';


function OTPVerification({ formData, errors, nextStep, prevStep, setloader, setErrors }) {
    const [otp, setOtp] = useState("");
    const [seconds, setSeconds] = useState(30);
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);

    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;



        if (!otp) {
            formIsValid = false;
            _errors["otp"] = "Please Enter Otp";
        }
        if (otp.length != 5) {
            formIsValid = false;
            _errors["otp"] = "Enter correct  Otp";
        }
        if (isNaN(otp)) {
            formIsValid = false;
            _errors["otp"] = "Otp Must be Number";
        }


        setErrors(_errors);
        return formIsValid;
    };
    // const handle = () => {
    //     navigate('/');
    //     // nextStep()
    // }
    useEffect(() => {
        // nextStep()
    }, [])
    const nextMove = async () => {
        try {
            const data =
            {
                email: formData?.email ? formData?.email : localStorage.getItem('email'),
                verificationCode: Number(otp),
            }
            if (handleValidation()) {
                setloader(true)
                const result = await EmailVerficationAPI(data);

                if (result.status = 200) {
                    setloader(false)
                   

                    if (result?.data) {
                        toast.success(result.message)
                        authCtx.login(result?.data?.access_token, result?.data?.refresh_token);
                        localStorage.setItem('userID', result.data.userExists
                            .id);
             
                        nextStep()
                    }

                }
                else {
                    setloader(false)
                    toast.error(result.message)
                }
            }



        }
        catch (err) {
            setloader(false)
            toast.error(err)
        }
    };

    const resendOTP = (e) => {
        e.preventDefault();
        const email = localStorage.getItem("email");
        const data = {
            email: email ? email : formData?.email
        };

        try {
            resendOTPAPI(data).then(result => {

                if (result?.status === 200) {
                    toast.success(result?.data?.view);
                    setSeconds(30);
                }

            });
        } catch (err) {
            toast.error(err);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    return (
        <>
            <div>
                <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-x-20 mb-96' >
                    <div>
                        <p className='mb-3'>Enter OTP sent to
                            <span className='mx-2'>
                                {formData.email}
                            </span>
                        </p>
                        <input type="number" name='otp' value={otp} maxlength="5" minLength="5"
                            onChange={({ target }) => {
                                const value = target.value;
                                if (value.length <= 5) {
                                    setOtp(value);
                                }
                            }}
                            placeholder='Enter Otp' className='p-2.5' />
                        {errors.otp && <p className="text-red-500">{errors.otp}</p>}
                        <div className="countdown-text my-1">
                            {seconds > 0 ? (
                                <p>
                                    Time Remaining: {seconds < 10 ? `0${seconds}` : seconds} seconds
                                </p>
                            ) : (
                                <p>Didn't receive the code?</p>
                            )}

                            <button
                                disabled={seconds > 0}
                                style={{
                                    color: seconds > 0 ? "rgb(231 231 222)" : "#FFF",
                                }}
                                className='save-continue-btn my-5'
                                onClick={(e) => resendOTP(e)}
                            >
                                Resend OTP
                            </button>
                        </div>
                    </div>
                </div>

                <div className='mt-12 pt-12'>
                    <div className='flex items-center '>
                        {/* <button type='button' className='back-btn mr-4' onClick={() => prevStep()}>Back</button> */}
                        <button type='button' onClick={() => nextMove()} className='save-continue-btn' >Sign Up</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OTPVerification;
