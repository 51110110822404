import React from 'react'

function DescModal(props) {
    const { allData } = props;
    return (
        <div className='text-center'><p dangerouslySetInnerHTML={{ __html: allData?.description }}>

        </p></div>
    )
}

export default DescModal