import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import classNames from 'classnames'
import cross from '../Assets/images/icons/cancel.svg'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  palette: {
    background: {
      default: '#e4f0e2',
    },
  },
}))

const sizes = {
  xl: 'max-w-7xl',
  md: 'max-w-xl',
  sm: 'max-w-lg',
}

const CommonModal = ({
  handleModalData,
  isModalOpen,
  modalData,
  modalSize,
  closeModal,
  frontModal
}) => {
  const classes = useStyles()
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div>
    {
      frontModal ? ( <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
          onClick: handleBackdropClick, // Prevent closing on backdrop click
        }}
      >
        <Fade in={isModalOpen}>
          <div
            id="custom-modal" style={{ height: '1000px', minWidth: '850px' }} 
            className={classNames({
              [classes.paper]: true,
              [sizes[modalSize]]: true,
            })}
          >
            {/* <span className="cross" onClick={closeModal}>
              <img src={cross} alt="" />
            </span> */}
            {modalData}
          </div>
        </Fade>
      </Modal>):(
<Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isModalOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div
          id="custom-modal"
          className={classNames({
            [classes.paper]: true,
            [sizes[modalSize]]: true,
          })}
        >
          {/* <span className="cross" onClick={closeModal}>
            <img src={cross} alt="" />
          </span> */}
          {modalData}
        </div>
      </Fade>
    </Modal>)
    }
    
   
  </div>
  )
}

export default CommonModal
