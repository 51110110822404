import React, { useContext, useState } from 'react'
import { ModalContext } from '../../Context';
import categary from "../../Assets/images/icons/category.svg"
import science from "../../Assets/images/icons/science.svg"
import cart from "../../Assets/images/icons/garden_cart.svg"
import oilberriericon from "../../Assets/images/icons/oil_barreltwo.svg"
import waterIcon from "../../Assets/images/icons/water_ec.svg"
import watertwoIcon from "../../Assets/images/icons/water_ectwo.svg"
import oilbarriericonthree from "../../Assets/images/icons/oil_barrelthree.svg"
import arrow_down from "../../Assets/images/icons/arrow_circle_down.svg"
import arrow_up from "../../Assets/images/icons/arrow_circle_up.svg"
import flight from "../../Assets/images/icons/flight.svg"
import business from "../../Assets/images/icons/business_center.svg"
import factory from "../../Assets/images/icons/factory.svg"
import precision_manufacture from "../../Assets/images/icons/precision_manufacturing.svg"
import saving from "../../Assets/images/icons/savings.svg"
import savingtwo from "../../Assets/images/icons/savingstwo.svg"
import grain from "../../Assets/images/icons/grain.svg"
import water_four from "../../Assets/images/icons/water_ecfours.svg"
import water_five from "../../Assets/images/icons/water_ecfive.svg"
import oil_barrelfour from "../../Assets/images/icons/oil_barrelfour.svg"
import oil_barrelfive from "../../Assets/images/icons/oil_barrelfive.svg"
import inventry from "../../Assets/images/icons/inventory.svg"
import { useNavigate } from 'react-router-dom';
import { ViewScopeApi, getYear } from '../../Utils/services';
import Loader from "../../Components/Common/Loader"
import DescModal from './DescModal';
import { toast } from 'react-toastify';
import info from "../../Assets/images/icons/info.svg"

const labs = [{
    icons: categary,
    name: "Purchased Raw Materials",
    links: "purchased-raw-materials"
},
{
    icons: science,
    name: "Purchased Chemicals and Gases",
    links: "purchased-chemicals-and-gases"
},
{   
    icons: cart,
    name: "Purchased Capital Goods",
    links: "puchased-capital-goods"
},
{
    icons: oilberriericon,
    name: "Supplier Fuel Consumption",
    links: "supplier-fuel-consumption"
},
{
    icons: waterIcon,
    name: "Supplier Electricity Consumption",
    links: "supplier-electricity-consumption"

},

{
    icons: oilbarriericonthree,
    name: "Buyer Electricity Consumption",
    links: "buyer-electricity-consumption"
},
{
    icons: watertwoIcon,
    name: "Buyer Fuel Consumption",
    links: "buyer-fuel-consumption"
},
{
    icons: arrow_down,
    name: "Inbound Transport",
    links: "inbound-transport"
},
{
    icons: arrow_up,
    name: "Outbound Transport",
    links: "outbound-transport"
},
{
    icons: flight,
    name: "Business Travel",
    links: "business-travel"
},
{
    icons: business,
    name: "Employee Commuting",
    links: "employee-commuting"
},
{
    icons: factory,
    name: "Warehouse Fuel Consumption",
    links: "warehouse-fuel-consumption"
},
{
    icons: precision_manufacture,
    name: "Warehouse Electricity Consumption",
    links: "warehouse-electricity-consumption"
},
{
    icons: saving,
    name: "Supplier Leased Assests",
    links: "supplier-leased-assests"
},
{
    icons: savingtwo,
    name: "Buyer Leased Assets",
    links: "buyer-leased-assets"
},
{
    icons: grain,
    name: "Waste Materials",
    links: "waste-materials"
},
{
    icons: oil_barrelfour,
    name: "Subsidiary Fuel Consumption",
    links: "subsidiary-fuel-consumption"
},
{
    icons: water_four,
    name: "Subsidiary Electricity Consumption",
    links: "subsidiary-electricity-consumption"
},
{
    icons: water_four,
    name: "Subsidiary Electricity Consumption",
    links: "subsidiary-electricity-consumption"
},
{
    icons: inventry,
    name: "Use of Sold Products",
    links: "use-of-sold-product"
},
{
    icons: inventry,
    name: "Processing of Sold Products",
    links: "processing-of-sold-products"
},  
{
    icons: inventry,
    name: "End-of-Life Treatment of Sold Products ",
    links: "end-of-life-treatment-of-sold-products"
},
{
    icons: oil_barrelfive,
    name: "Franchise Fuel Consumption",
    links: "franchise-fuel-consumption"
},
{
    icons: water_five,
    name: "Franchise Electricity Consumption",
    links: "franchise-electricity-consumption"
},
{
    icons: water_five,
    name: "Raw Meterials",
    links: "row-materials"
},

]

const   ScopeLabsthree = () => {
    const [loader, setLoader] = useState(false)
    const nevigation = useNavigate();
    const currYear = localStorage.getItem("form");
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;

    const handleElectricity = (id) => {
        nevigation(`/new-assessment/scope-three/${id?.links}`)
    }
    const [innerData, setinnerData] = useState();
    const navigation = useNavigate();
    const userId = localStorage.getItem("userID")

    const handleNevigation = (data) => {    
        
        const filteredLabs = labs.filter((val) => val.name == data);
        if (filteredLabs.length > 0) {
            const slug = filteredLabs[0].links;
            navigation(`/new-assessment/scope-three/${slug}`);

        } else {
            console.log("Lab not found");
        }
    }


    const getData = async () => {

        if (currYear !== null) {
            setLoader(true)
            const result = await ViewScopeApi(userId, currYear, 3);
            setinnerData(result?.res?.data);
            setLoader(false)
        } else {
            setinnerData([])
        }

    }

    const handleHover = (data) => {


        if (data?.description) {
            const Addsupplier = <DescModal allData={data} />

            handleModalData(Addsupplier, "md");
        }
        else {
            toast.warning("No Description is There")
        }



    }
    React.useEffect(() => {
        getData()
    }, [])
    return (
        <div className='pt-12'>
            <Loader loader={loader} />
            <div className="grid grid-cols-1  xl:grid-cols-3  md:grid-cols-2 gap-4">
                {
                    innerData?.map((val) => (
                        <div onClick={() => handleNevigation(val.product)} className='flex items-center cursor-pointer labs justify-between'>
                            <div className=' flex items-center cursor-pointer' >
                                <img src={saving} alt="" />
                                <span className='px-6'>{val.product}</span>
                            </div>
                            <div onMouseEnter={() => handleHover(val)} >
                                <img src={info} alt="" />
                            </div>

                        </div>


                    ))
                }
            </div>
        </div>
    )
}

export default ScopeLabsthree