import axios from "axios";
import {
  ADDASSESMENT,
  ADDCEMA,
  ADDPLANT,
  ASSESSMENT_FILTER,
  ASSESSMENT_ONE,
  ASSESSMENT_THREE,
  ASSESSMENT_THREE_FILTER,
  ASSESSMENT_TWO_FILTER,
  CEMA,
  EDITPROFILE,
  LOGIN,
  FILTER,
  MONTH,
  REPORT,
  ScopeFilter,
  SIGNUP,
  SUBMIT,
  SUPLIER,
  UPDATESUPPLY,
  VIEWPROFILE,
  VIEWSUPLIER,
  YEAR_DATA,
  AssignmentPage,
  ViewScopeData,
  ViewPlant,
  Plantedit,
  DeletePlant,
  PostPlant,
  YEAR_FILTER,
  FilterChecker,
  POSTREPORT,
  GetCEMA,
  GETASSURANCE,
  GETSTATUS,
  GETSCOPEOPTION,
  GEtDATASTATUS,
  GETASSEMENT,
  EmailVerfication,
  DeleteScope,
  resendOTP,
  PaymentConfirmation,
  Postsupplyfile,
  REFRESH_TOKEN,
  ViewGraph,
  UPDATESUBMIT,
  Contact,
  GETASSURANCEFILE,
  GETREPORTFILE,
  RECENTACTIVITY,
  PAYMENT,
  DOWNLOADREPORT,
  NEWSASSEMENT,
  FINANCIALYEAR,
  SCOPE3DATA,
  GETCATEGORY,
  GETEMISSION
} from "./routes";

export const BASE_URL = "https://www.api.enscope.enengreen.com/"; //production///filter

axios.defaults.baseURL = BASE_URL;
const token = localStorage.getItem("token");

export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const sinUpService = async (data) => {
  try {
    const response = await axios.post(SIGNUP, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addPlant = async (data) => {
  try {
    const response = await axios.post(ADDPLANT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editProfile = async (data, id) => {
  try {
    const response = await axios.put(`${EDITPROFILE}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getViewProfile = async (data) => {
  try {
    const response = await axios.get(VIEWPROFILE, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getViewSuplier = async (data) => {
  try {
    const response = await axios.get(VIEWSUPLIER, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addSuplier = async (data) => {
  try {
    const response = await axios.post(SUPLIER, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteSuplier = async (data) => {
  try {
    const response = await axios.delete(SUPLIER, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addAsssementOne = async (data) => {
  try {
    const response = await axios.post(ASSESSMENT_ONE, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAsssessmentOne = async (data) => {
  try {
    const response = await axios.post(ASSESSMENT_FILTER, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteAsssessmentOne = async (data) => {
  try {
    const response = await axios.delete(`${ASSESSMENT_ONE}/${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getMonth = async (data) => {
  try {
    const response = await axios.get(MONTH, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAsssessmentTwo = async (data) => {
  try {
    const response = await axios.post(ASSESSMENT_TWO_FILTER, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const AddAsssessmenttwo = async (data) => {
  try {
    const response = await axios.post(ADDASSESMENT, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAsssessmentThree = async (data) => {
  try {
    const response = await axios.post(ASSESSMENT_THREE_FILTER, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getYear = async () => {
  try {
    const response = await axios.get(YEAR_DATA, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteAsssessmentThree = async (data) => {
  try {
    const response = await axios.delete(`${ASSESSMENT_THREE}/${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const AddAsssessmentthree = async (data) => {
  try {
    const response = await axios.post(ASSESSMENT_THREE, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const submitData = async (data) => {
  try {
    const response = await axios.post(SUBMIT, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const updatesubmitData = async (id, data) => {
  try {
    const response = await axios.put(`${UPDATESUBMIT}/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const updateSupply = async (id, data) => {
  try {
    const response = await axios.put(`${UPDATESUPPLY}/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteSupply = async (id) => {
  try {
    const response = await axios.delete(`${UPDATESUPPLY}/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getReport = async (data) => {
  try {
    const response = await axios.get(`${REPORT}/${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const submitFilterData = async (data) => {
  try {
    const response = await axios.post(FILTER, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const CemaEdit = async (data) => {
  try {
    const response = await axios.post(ADDCEMA, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const FilterScopeData = async (data) => {
  try {
    const response = await axios.get(ScopeFilter, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AssignPage = async (data) => {
  try {
    const response = await axios.get(AssignmentPage, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ViewScopeApi = async (id, data, para) => {
  try {
    const response = await axios.get(`${ViewScopeData}/${data}/${para}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PlantView = async (data) => {
  try {
    const response = await axios.get(ViewPlant, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditPlant = async (data) => {
  try {
    const response = await axios.put(Plantedit + data.id, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PlantDelete = async (data) => {
  try {
    const response = await axios.delete(DeletePlant + data, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PlantPost = async (data) => {
  try {
    const response = await axios.post(PostPlant, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const FilterYear = async (data) => {
  try {
    const response = await axios.get(`${YEAR_FILTER}`, {
      // headers: {
      //   Authorization: "Bearer " + token,
      // },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AssessmentCema = async (data) => {
  try {
    const response = await axios.get(CEMA, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const CheckerFilter = async (data) => {
//   try {
//     const response = await axios.get(FilterChecker, {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const CheckerFilter = async (data) => {
  try {
    const response = await axios.get(`${FilterChecker}${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ReportPost = async (data) => {
  try {
    const response = await axios.post(POSTREPORT, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETSAVEDCEMADATA = async () => {
  try {
    const response = await axios.post(GetCEMA, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETCERTIFICATE = async (data) => {
  try {
    const response = await axios.post(GETASSURANCE, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETSTATUSDATA = async (data) => {
  try {
    const response = await axios.get(`${GETSTATUS}/${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ReportGet = async (data) => {
  try {
    const response = await axios.get(POSTREPORT, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETASSURANCEAPI = async (data) => {
  try {
    const response = await axios.get(`${GETASSURANCE}/${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETSCOPEOPTIONDATA = async (data, id) => {
  try {
    const response = await axios.get(`${GETSCOPEOPTION}/${data}/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GEtDATASTATUSAPI = async (data, id) => {
  try {
    const response = await axios.get(`${GEtDATASTATUS}/${data}/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETASSEMENTAPI = async (data, id) => {
  try {
    const response = await axios.get(`${GETASSEMENT}/${data}/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const EmailVerficationAPI = async (data) => {
  try {
    const response = await axios.post(EmailVerfication, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deletescope = async (year, id) => {
  try {
    const response = await axios.delete(`${DeleteScope}/${id}/${year}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const resendOTPAPI = async (data) => {
  try {
    const response = await axios.post(resendOTP, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PaymentConformationAPI = async () => {
  try {
    const response = await axios.get(PaymentConfirmation, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const SupplyFileAPI = async (file) => {
  try {
    const response = await axios.post(Postsupplyfile, file, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PostPdfAPi = async (id) => {
  try {
    const response = await axios.get(`pdf/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ViewGraphAPi = async (id, year) => {
  try {
    const response = await axios.get(`${ViewGraph}/${id}/${year}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const test = async (data) => {
  try {
    const response = await axios.post(REFRESH_TOKEN, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const EditRequestAPi = async (data, year) => {
  try {
    const response = await axios.get(`${data}/${year}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ContactAPi = async (data) => {
  try {
    const response = await axios.post(`${Contact}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETREPORTFILEAPi = async (data) => {
  try {
    const response = await axios.get(`${GETREPORTFILE}${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob", // Set the response type to 'blob' for handling binary data (file)
    });

    if (response.status === 200) {
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      return { fileUrl };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};

export const GETASSURANCEFILEAPi = async (data) => {
  try {
    const response = await axios.get(`${GETASSURANCEFILE}${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob", // Set the response type to 'blob' for handling binary data (file)
    });

    if (response.status === 200) {
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      return { fileUrl };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};

export const RECENTACTIVITYAPi = async (id, year) => {
  try {
    const response = await axios.get(`${RECENTACTIVITY}${id}/${year}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PAYMENTAPi = async (data) => {
  try {
    const response = await axios.post(`${PAYMENT}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DOWNLOADREPORTAPi = async (yaer) => {
  try {
    const response = await axios.get(`${DOWNLOADREPORT}${yaer}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    });
    if (response.status === 200) {
      return response.data; // Return the blob directly
    } else {
      throw new Error("Failed to download report"); // Throw an error if download fails
    }
  } catch (err) {
    throw new Error(err.message); // Re-throw the error
  }
};
export const NEWSASSEMENTAPi = async (id, year) => {
  try {
    const response = await axios.get(`${NEWSASSEMENT}${id}/${year}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const FINANCIAYEARAPi = async (year) => {
  try {
    const response = await axios.get(`${FINANCIALYEAR}/${year}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const SCOPE3DATAPi = async (data,data2) => {
  try {
    const response = await axios.get(`${SCOPE3DATA}/${data}/${data2}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETCATEGORYAPi = async () => {
  try {
    const response = await axios.get(`${GETCATEGORY}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const POSTEMISSIONAPi = async (data) => {
  try {
    const response = await axios.post(`${GETEMISSION}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};