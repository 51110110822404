
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, GETSCOPEOPTIONDATA, deleteAsssessmentThree, getAsssessmentThree, SCOPE3DATAPi, GETCATEGORYAPi, POSTEMISSIONAPi } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../../Common/DeleteConfirm";
import Date from "../../Date";
import AutoSuggestion from "../../AutoSuggestion"


const ProcessSoldProductTable = ({ month, YearData, CreatOn, manthFilter, setloader, monYear }) => {

    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [gettrnsport, setGettrnsport] = useState();
    const [options, setOptions] = useState({ option1: [], option2: [] });
    const { plant } = useContext(AuthContext);
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { waybill_date: "", origin_place: "", delivery_place: "", fuel_type: "", energy_source_required: "", quantity_sold: '', plant_type: plant, category: "Processing of Sold Products", assessment_month: month, assessment_year: year_data, created_on: CreatOn, unit: '', emission_value: '' }]);
    }
    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };


    const updateDate = (index, waybill_date) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].waybill_date = waybill_date
        setFuelDetails(newFuelDetails)

    }

    const updateNameOforigin = (index, origin_place) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].quantity_sold = origin_place
        setFuelDetails(newFuelDetails)


    }



    const updatedeliveryplace = (index, delivery_place) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].quantity = delivery_place
        setFuelDetails(newFuelDetails)

    }

    const updateModetransport = (index, energy_source_required) => {

        let filter_data = options.option1?.filter((item) => item.id === energy_source_required);

        const newFuelDetails = [...fuelDetails]

        newFuelDetails[index].energy_source_required = filter_data[0]?.label
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].emission_value = filter_data[0]?.value
        newFuelDetails[index].scope_data = filter_data[0]?._value
        setFuelDetails(newFuelDetails)

    }

    const updateFueltype = (index, energy_source_required) => {
        let filter_data = options.option2?.filter((item) => item?.id == energy_source_required);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].energy_source_required = filter_data[0]?.label
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].emission_value = filter_data[0]?.value
        newFuelDetails[index].scope_data = filter_data[0]?._value
        setFuelDetails(newFuelDetails)
    }


    const updateDistance = (index, quantity_sold) => {
        let filter_data = options.option1?.filter((item) => item?.label == fuelDetails[index]?.quantity_sold);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].fuel_consumed_qty = quantity_sold;

        setFuelDetails(newFuelDetails)
    }

    const SaveData = async () => {

        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    scopes3: fuelDetails,
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };

    const getSelectData = async () => {
        const result = await GETSCOPEOPTIONDATA(3, 24);
        const datas1 = result?.res?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));


        setOptions((prevState) => ({
            ...prevState,
            option1: datas1,

        }));

    }
    const getFuel = async () => {
        const data =
        {
            'sub_category': gettrnsport,
            'category': 24
        }
        const category_data = await POSTEMISSIONAPi(data);
        const datas1 = category_data?.data?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));


        setOptions((prevState) => ({
            ...prevState,
            option2: datas1,

        }));

    }
    const updateNameOfSoldPorduct = (index, sold_product_name) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].sold_product_name = sold_product_name
        setFuelDetails(newFuelDetails)

    }
    useEffect(() => {

        getFuel();
    }, [gettrnsport]);

    useEffect(() => {
        allAssessment();
        getSelectData();
    }, [month, plant]);

    const allAssessment = async () => {
        setloader(true);
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree({
            plant_type: plant,
            category: "Processing of Sold Products",
            assessment_month: manthFilter,
            assessment_year: yaer_data
        });
        setloader(false)
        let filterData = result?.filter((item) => item?.assessment_month === manthFilter);
        setFuelDetails(filterData);
    };

    const validateFields = () => {

        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};
            if (!fuel.sold_product_name) {
                formIsValid = false;
                errors.sold_product_name = 'Please  enter the sold product name';
            }
            else if (!isNaN(fuel.sold_product_name)) {
                formIsValid = false;
                errors.sold_product_name = 'sold product name must be a string.';
            }

            if (!fuel.quantity_sold) {
                formIsValid = false;
                errors.quantity_sold = 'Please  enter the   Quantity Sold ';
            }
            else if (isNaN(fuel.quantity_sold)) {
                formIsValid = false;
                errors.quantity_sold = 'Quantity Sold  must be a number.';
            }
            if (!fuel.quantity) {
                formIsValid = false;
                errors.quantity = 'Please  enter the Weight of Product';
            }
            else if (isNaN(fuel.quantity)) {
                formIsValid = false;
                errors.quantity = 'Weight of Product  must be a Number.';
            }
            if (!fuel.energy_source_required) {
                formIsValid = false;
                errors.energy_source_required = 'Please select the Energy Source';
            }
            if (!fuel.fuel_type) {
                formIsValid = false;
                errors.fuel_type = 'Please  select the 	Processing Type';
            }

            if (!fuel.fuel_consumed_qty && fuel.fuel_consumed_qty !== 0) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Please enter the Energy Consumed.';
            }
            else if (isNaN(fuel.fuel_consumed_qty)) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Energy Consumed must be a Number.';
            }
            else if (fuel.fuel_consumed_qty < 0) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Energy Consumed must be a greater than -1.';
            }
            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };
    const updatefuelType = (index, fuel_type) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].fuel_type = fuel_type ;
        setFuelDetails(newFuelDetails)
    }

    useEffect(() => {
        allAssessment();
        getSelectData();
    }, [month, plant]);

    return (
        <div>
            <div className='plant-table mt-4' style={{ 'overflow-x': 'visible' }}>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name of the Sold Product</th>
                            <th>Quantity Sold </th>
                            <th>Weight of Product (in kg)</th>
                            <th>Processing Type</th>
                            <th>Energy Source for Processing</th>
                            <th>Energy Consumed for Processing (kg/ltr/m3/kWh)</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {

                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            const isQuantityDisabled = !isRowEdited || !fuel.energy_source_required ;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.sold_product_name}
                                            onChange={(event) =>
                                                updateNameOfSoldPorduct(index, event.target.value)
                                            }
                                        />

                                        {hasErrors && hasErrors.sold_product_name && <span className="error">{hasErrors.sold_product_name}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text' disabled={!isRowEdited}
                                            value={fuel.quantity_sold}
                                            onChange={(event) =>
                                                updateNameOforigin(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.quantity_sold && <div className="error">{hasErrors.quantity_sold}</div>}
                                    </td>
                                    <td>
                                        <input
                                            type='text' disabled={!isRowEdited}
                                            value={fuel.quantity}
                                            onChange={(event) =>
                                                updatedeliveryplace(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.quantity && <div className="error">{hasErrors.quantity}</div>}
                                    </td>
                                    <td>
                                        <input
                                            type='text' disabled={!isRowEdited}
                                            value={fuel.fuel_type}
                                            onChange={(event) =>
                                                updatefuelType(index, event.target.value)
                                            }
                                        />

                                        {hasErrors && hasErrors.fuel_type && <div className="error">{hasErrors.fuel_type}</div>}
                                    </td>
                                    <td>

                                        <AutoSuggestion data={options.option1} prevalue={fuel.energy_source_required} updateNameOfChemical={updateModetransport} index={index} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.energy_source_required && <div className="error">{hasErrors.energy_source_required}</div>}
                                    </td>


                                    <td>
                                        <input
                                            type='text' disabled={isQuantityDisabled}
                                            value={fuel.fuel_consumed_qty}
                                            onChange={(event) =>
                                                updateDistance(index, event.target.value)
                                            }
                                        />
                                        {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                        {hasErrors && hasErrors.fuel_consumed_qty && <span className="error">{hasErrors.fuel_consumed_qty}</span>}

                                    </td>
                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProcessSoldProductTable;
