import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { getYear, getMonth, format } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";

export default function Index(props) {
  const { monYear, index, handle, value, disabled } = props;
  const arr = monYear?.split("-");
  const customYear = parseInt(arr[1], 10);
  const customMonth = arr[0];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedDate, setSelectedDate] = useState(null);
  const years = range(2020, getYear(new Date()) + 10, 1);

  const selectedYear = customYear;
  const selectedMonth = months.indexOf(customMonth);

  const formatDate = (date) => {
    return format(date, "dd/MM/yyyy");
  };

  useEffect(() => {
    if (value) {
      const dateParts = value.split("/");
      const year = parseInt(dateParts[2], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[0], 10);
      setSelectedDate(new Date(year, month, day));
    } else {
      setSelectedDate(new Date(customYear, selectedMonth, 1));
      handle(index, formatDate(new Date(customYear, selectedMonth, 1)));
    }
  }, [value, customYear, selectedMonth]);

  const handleDateChange = (date) => {
    if (date != null) {
      setSelectedDate(date);
      handle(index, formatDate(date));

    }

  };

  const filterDate = (date) => {
    const yearMatches = getYear(date) === selectedYear;
    const monthMatches = getMonth(date) === selectedMonth;
    return yearMatches && monthMatches;
  };

  return (
    <DatePicker
      showIcon
      required
      dateFormat="dd/MM/yyyy"
      className="custom-input-style"
      readOnly={disabled}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <select
            className="custom-select-style"
            value={selectedYear}
            onChange={({ target: { value } }) => changeYear(value)}
            disabled
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            className="custom-select-style"
            value={months[selectedMonth]}
            disabled
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      selected={selectedDate}
      onChange={handleDateChange}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      filterDate={filterDate}
    />
  );
}
