import React, { useEffect, useState } from 'react'
import { getYear,FINANCIAYEARAPi } from '../../../../Utils/services'
import EndofLifeTable from './EndofLifeTable';
import Loader from "../../Loader"



const EndofLifePlant = () => {
    const [plant, setPlantData] = useState([]);
    const [loader, setloader] = useState(false)
    let curryear = localStorage.getItem("form");


    const getAssessmentTwoApi = async () => {
        let curryear = localStorage.getItem("form");
        if (curryear) {
            setloader(true);
            try {
                let _year = curryear.split('-')[0]
                let result = await FINANCIAYEARAPi(_year);
                if (result?.res) {
                    setPlantData(result?.res);
                }

            } catch (error) {
                setloader(false);
                // Handle the error here, you can log it or show a message to the user
                console.error("Error fetching year data:", error);
            }
            finally {
                setloader(false);
            }



        }
    }

    useEffect(() => {
        getAssessmentTwoApi()
    }, [])


    return (
        <div className='pt-2'>
            <p className='profile-texts'>Donnelly-Wisozk Die cast manufacturing unit</p>
            <div className='pt-4'>
                <p className='ass-year'>FinancialYear : {curryear}</p>
            </div>
            <Loader loader={loader} />
            {
                plant?.map((item) => (
                    <>
                        <p className='pt-4'>{item?.created_on}</p>
                        <EndofLifeTable months={item?.assessment_month} YearData={item?.year} CreatOn={item?.created_on} manthFilter={item?.assessment_month} setloader={setloader} />

                    </>
                ))
            }
        </div>
    )
}

export default EndofLifePlant