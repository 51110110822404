import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import Business_plant from '../../../Components/Common/ScopeThreeTables/BusinessTravel/Business_plant'


const BusinessTravel = () => {



    return (
        <div>
            <InnerHeader innertitle="Business Travel" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Business Travel" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <Business_plant />
            </div>
        </div>
    )
}

export default BusinessTravel