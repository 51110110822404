import React, { useEffect } from 'react';
import { PAYMENTAPi } from '../../Utils/services';


function Payment() {
    const paymentMethod = async () => {
        const requestData = {
            "user_id": "34",
            "price": 22,
            "phone": "8786858585",
            "name": "Ravi Rai"
        };

        try {
            const response = await PAYMENTAPi(requestData);
            console.log(response, "responseresponse")
            if (response?.status === 200) {
                window.open(response?.data?.url, '_blank');
            }

        } catch (err) {
            // Handle errors
        }
    };

    useEffect(() => {
        paymentMethod();
    }, []);
    return (
        <div>Payment</div>
    )
}

export default Payment