import React, { useEffect, useState } from "react";
import { getAsssessmentTwo, getYear } from "../../../../Utils/services";
import StreamTableRow from "./StreamTableRow";
import Loader from "../../../Common/Loader"


function ElectricityPlantTable() {
    const [electricData, setElectricData] = useState([]);
    const [loader, setloader] = useState(false);

    const getAssessmentTwoApi = async () => {
        setloader(true)
        let result = await getYear();
        setElectricData(result?.res.data);
        setloader(false)
    }

    useEffect(() => {
        getAssessmentTwoApi()
    }, [])



    return (
        <>
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Month</th>
                            <th>Source of Steam</th>
                            <th>Steam Consumed</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {electricData?.map((rowData, index) => (
                            <>
                                <StreamTableRow key={rowData.id} data={rowData} index={index} YearData={rowData?.year} manthFilter={rowData?.assessment_month} getAssessmentTwoApi={getAssessmentTwoApi} setloader={setloader} />
                            </>

                        ))}
                    </tbody>
                </table>
                <Loader loader={loader} />

            </div></>

    );
}

export default ElectricityPlantTable;