import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, GETSCOPEOPTIONDATA, deleteAsssessmentThree, getAsssessmentThree, SCOPE3DATAPi, GETCATEGORYAPi, POSTEMISSIONAPi } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../../Common/DeleteConfirm";
import Date from "../../Date"
import AutoSuggestion from "../../AutoSuggestion"

const Businesstable = ({ month, YearData, CreatOn, manthFilter, setloader,monYear }) => {
    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [gettrnsport, setGettrnsport] = useState();
    const [options, setOptions] = useState({ option1: [], option2: [] });
    const { plant } = useContext(AuthContext);
    const year_data = localStorage.getItem("form")

    const addRow = () => {
        setFuelDetails([...fuelDetails, { travel_date: "", departure_place: "", arrival_place: "", transport_mode: "", fuel_type: "", covered_distance: '', plant_type: plant, category: "Business Travel", assessment_month: month, assessment_year: year_data, created_on: CreatOn, unit: '', emission_value: '' }]);
    }

    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };

    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};
            if (!fuel.travel_date) {
                formIsValid = false;
                errors.travel_date = 'Please select a  month.';
            }

            if (!fuel.departure_place) {
                formIsValid = false;
                errors.departure_place = 'Please enter the Place.';
            }
            else if (!isNaN(fuel.departure_place)) {
                formIsValid = false;
                errors.departure_place = 'Place must be a string.';
            }
            if (!fuel.arrival_place) {
                formIsValid = false;
                errors.arrival_place = 'Please enter the Place.';
            }
            else if (!isNaN(fuel.arrival_place)) {
                formIsValid = false;
                errors.arrival_place = 'Place must be a string.';
            }
            if (!fuel.transport_mode) {
                formIsValid = false;
                errors.transport_mode = 'Please enter the Mode.';
            }
            else if (!isNaN(fuel.transport_mode)) {
                formIsValid = false;
                errors.transport_mode = 'Mode must be a string.';
            }
            if (!fuel.fuel_type) {
                formIsValid = false;
                errors.fuel_type = 'Please enter the Fuel.';
            }
            else if (!isNaN(fuel.fuel_type)) {
                formIsValid = false;
                errors.fuel_type = 'Fuel must be a string.';
            }
            if (!fuel.covered_distance && fuel.covered_distance !== 0) {
                formIsValid = false;
                errors.covered_distance = 'Please enter the Distance.';
            }
            else if (isNaN(fuel.covered_distance)) {
                formIsValid = false;
                errors.covered_distance = 'Distance must be a Number.';
            }
            else if (fuel.covered_distance < 0) {
                formIsValid = false;
                errors.covered_distance = 'Distance must be a greater than -1.';
            }
            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };

    const updateDate = (index, travel_date) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].travel_date = travel_date
        setFuelDetails(newFuelDetails)

    }

    const updateDepartureplace = (index, departure_place) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].departure_place = departure_place
        setFuelDetails(newFuelDetails)

    }



    const updateArival = (index, arrival_place) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].arrival_place = arrival_place
        setFuelDetails(newFuelDetails)

    }

    const updateModetransport = (index, transport_mode) => {
        let filter_data = options.option1?.filter((item) => item.id === transport_mode);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].transport_mode = filter_data[0]?.label 
        setFuelDetails(newFuelDetails)


    }
    const updateFueltype = (index, fuel_type) => {
        let filter_data = options.option2?.filter((item) => item.id === fuel_type);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].fuel_type = filter_data[0]?.label 
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";

        setFuelDetails(newFuelDetails)
    }


    const updateDistance = (index, covered_distance) => {
        let filter_data = options.option2?.filter(item => item.label == fuelDetails[index].fuel_type && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].covered_distance = covered_distance;
        newFuelDetails[index].emission_value = filter_data[0]?.value
        newFuelDetails[index].scope_data = filter_data[0]?._value
        setFuelDetails(newFuelDetails)


    }



    const SaveData = async () => {
        
        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    "scopes3": fuelDetails
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                setEditIndex(-1);
  
            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }


    };
    const getSelectData = async () => {
        const result = await SCOPE3DATAPi(3, 13);
        const datas1 = result?.res?.map((item) => ({
            id: item?.category_name,
            label: item?.category_name,
            unit: item?.category_name,
            value: item?.category_name,
            _value: item?.category_name,
        }));


        setOptions((prevState) => ({
            ...prevState,
            option1: datas1,

        }));

    }
    const getFuel = async () => {
        const data =
        {
            'sub_category': gettrnsport,
            'category': 13
        }
        const category_data = await POSTEMISSIONAPi(data);
        const datas1 = category_data?.data?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));


        setOptions((prevState) => ({
            ...prevState,
            option2: datas1,

        }));

    }
    useEffect(() => {

        getFuel();
    }, [gettrnsport]);

    const allAssessment = async (month) => {
        setloader(true)
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree(
            {
                
                assessment_year: yaer_data,
                category: "Business Travel",
                assessment_month: manthFilter,
                plant_type: plant,
            }

        );
            
        let filterData = result?.filter((item) => item.assessment_month === month);
    
        setFuelDetails(filterData);

        setloader(false)
    }


    useEffect(() => {
        allAssessment(month);
        getSelectData();
    }, [month, plant])


    return (
        <div >
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Date of Travel </th>
                            <th>Departure Place</th>
                            <th>Arrival Place</th>
                            <th>Mode of Transport</th>
                            <th>Type of Fuel</th>
                            <th>Distance Covered</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            const isQuantityDisabled = !isRowEdited || !fuel.fuel_type || !fuel.transport_mode;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                    <Date index={index} monYear={monYear} handle={updateDate} value={fuel.travel_date} disabled={!isRowEdited}  />
                                        
                                        {/* <input type="date" value={fuel.travel_date}
                                            onChange={(event) =>
                                                updateDate(index, event.target.value)
                                            }
                                        /> */}
                                        {hasErrors && hasErrors.travel_date && <span className="error">{hasErrors.travel_date}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            value={fuel.departure_place}
                                            disabled={!isRowEdited} 
                                            onChange={(event) =>
                                                updateDepartureplace(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.departure_place && <span className="error">{hasErrors.departure_place}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            value={fuel.arrival_place}
                                            disabled={!isRowEdited} 
                                            onChange={(event) =>
                                                updateArival(index, event.target.value)
                                            }
                                        />
                                       
                                        {hasErrors && hasErrors.arrival_place && <span className="error">{hasErrors.arrival_place}</span>}
                                    </td>
                                    <td>
                                        <AutoSuggestion data={options.option1} prevalue={fuel.transport_mode} updateNameOfChemical={updateModetransport} index={index} disabled={!isRowEdited}  setField={setGettrnsport}  />
                                        {hasErrors && hasErrors.transport_mode && <div className="error">{hasErrors.transport_mode}</div>}
                                    </td>
                                    <td>
                                        <AutoSuggestion data={options.option2} prevalue={fuel.fuel_type} updateNameOfChemical={updateFueltype} index={index} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.fuel_type && <div className="error">{hasErrors.fuel_type}</div>}
                                    </td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={isQuantityDisabled} 
                                            
                                            value={fuel.covered_distance}
                                            onChange={(event) =>
                                                updateDistance(index, event.target.value)
                                            }
                                        />
                                         {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                        {hasErrors && hasErrors.covered_distance && <span className="error">{hasErrors.covered_distance}</span>}
                                    </td>
                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            );
                        })}


                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Businesstable;