import React from 'react'
import { useState } from 'react';
import Loader from '../../Components/Common/Loader'
import logoIcon from "../../Assets/images/SignUp/actual.png"
import geranalIcon from "../../Assets/images/SignUp/generalicon.png"
import geranalIcon2 from "../../Assets/images/SignUp/generalicon2.svg"
import geranalIcon3 from "../../Assets/images/SignUp/generalicon3.svg"
import geranalIcon4 from "../../Assets/images/SignUp/generalicon4.svg"
import checkIcon from "../../Assets/images/SignUp/tick.svg"
import genral_5 from "../../Assets/images/SignUp/genral_5.svg"
import GeneralDetails from "./GeneralDetails"
import OTPVerification from "./OTPVerification";
import BillingDetails from "./BillingDetails"
import "react-phone-input-2/lib/style.css";
import { useNavigate } from 'react-router-dom';



const SignUp = () => {
    const [togale, setToggle] = useState(false);
    const [loader, setloader] = useState(false);
    const [currentStep, setCurrentStep] = useState(() => {
        const storedStep = localStorage.getItem('step');
        return storedStep ? parseInt(storedStep) : 0;
    });
    const [formData, setFormData] = useState({ name: "", designation: "", address: "", gst_no: "", company_name: "", email: "", company_type: "", password: "", otp: "", IsAdmin: 0, userType: 1, confrimPassword: '', plan_type: "", subscription_type: 0, price: 2499 });
    const [phoneData, setPhoneData] = useState();
    const [tellyphoneData, setTellyPhoneData] = useState();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleLoginPage = () => {
        navigate('/login')
    }



    return (
        <div className='signup'>
            <header className='p-8 flex justify-between'>
                <div>
                    <img src={logoIcon} alt="" />
                </div>
                <div className='flex'>
                    <div className='flex items-center'>
                        <div className='flex items-center'>
                            <img src={currentStep === 0 ? geranalIcon : checkIcon} alt="" />
                            <p className='ml-2 generalIcon'>General Details</p>
                        </div>

                        <div className='flex items-center pl-4'>
                            <img src={currentStep === 1 ? geranalIcon3 : currentStep > 2 ? checkIcon : geranalIcon2} alt="" />
                            <p className='ml-2 generalIcon'>OTP Verification</p>
                        </div>

                        <div className='flex items-center pl-4'>
                            <img src={currentStep === 2 ? genral_5 : geranalIcon4} alt="" />
                            <p className='ml-2 generalIcon'>Billing Details</p>
                        </div>
                    </div>
                </div>
            </header>
            <hr />
            <hr className={currentStep === 0 ? 'horizontal-line' : currentStep === 1 ? 'horizontal-linehalf' : 'horizontal-linefull'} />

            <form action="">
                <Loader loader={loader} />
                <div className='pt-16 flex justify-center'>
                    <div className='sign-up-page px-4'>


                        {currentStep === 0 && (
                            <GeneralDetails
                                formData={formData}
                                errors={errors}
                                setErrors={setErrors}
                                handleInputChange={handleInputChange}
                                nextStep={nextStep}
                                handleLoginPage={handleLoginPage}
                                phoneData={phoneData}
                                setPhoneData={setPhoneData}
                                tellyphoneData={tellyphoneData}
                                setTellyPhoneData={setTellyPhoneData}
                                setloader={setloader}
                                setFormData={setFormData}
                                setCurrentStep={setCurrentStep}

                            />

                        )}

                        {currentStep === 1 && (
                            <OTPVerification
                                formData={formData}
                                errors={errors}
                                setErrors={setErrors}
                                handleInputChange={handleInputChange}
                                nextStep={nextStep}
                                prevStep={prevStep}
                                setloader={setloader}
                            />
                        )}
                        {currentStep === 2 && (
                            <BillingDetails
                                formData={formData}
                                errors={errors}
                                setErrors={setErrors}
                                handleInputChange={handleInputChange}
                                prevStep={prevStep}
                                phoneData={phoneData}
                                tellyphoneData={tellyphoneData}
                                setPhoneData={setPhoneData}
                                setTellyPhoneData={setTellyPhoneData}


                            />
                        )}



                    </div>

                </div>
            </form>



        </div>
    )
}

export default SignUp