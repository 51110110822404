import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import SteamPlantTable from '../../../Components/Common/ScopTwoPlantTable/Steam/SteamPlantTable'

const Steam_Heat = () => {


    return (
        <div>
            <InnerHeader  innertitle="Purchased Heat" title="Scope 2" />
            <div className='px-16'>
                <Backbtn title="Purchased Heat" />
                <Descriptions discrip="" />
                <InnerTabe />
                <SteamPlantTable />
                {/* <EctricityPlant /> */}
            </div>
        </div>
    )
}

export default Steam_Heat