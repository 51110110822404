import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import ChemicalGasPlant from '../../../Components/Common/ChemicalGasPlant'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import ChemicalandCumbustionplantTwo from '../../../Components/Common/ScopeThreeTables/Chemical-and-gas/ChemicalandCumbustionplantTwo'


const ChemicalAndCumbustionTwo = () => {
    return (
        <div>
            <InnerHeader  innertitle="Purchased Chemicals and Gases" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Purchased Chemicals and Gases" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <ChemicalandCumbustionplantTwo />
                {/* <ChemicalGasPlant /> */}
            </div>
        </div>
    )
}

export default ChemicalAndCumbustionTwo;