import React, { useEffect, useState } from 'react';
import { sinUpService, PaymentConformationAPI, PAYMENTAPi } from '../../Utils/services'
import { toast } from 'react-toastify';
import Loader from '../../Components/Common/Loader'
import logoIcon from "../../Assets/images/SignUp/actual.png"
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import axios from 'axios';


function DirectBilling({ formData, errors, handleInputChange, nextStep, setErrors, phoneData, tellyphoneData, prevStep }) {
    const [allData, setallData] = useState([]);
    const [loader, setloader] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state;
    const handleValidationTwo = () => {
        let _errors = {};
        let formIsValid = true;
        if (!formData.gstNo) {
            formIsValid = false;
            _errors["gstNo"] = "Please enter a GST number";
        }
        else if (!/^([0-9]{2}[a-zA-Z]{5}\d{4}[a-zA-Z]{1}\d[Zz]{1}[a-zA-Z\d]{1})$/.test(formData.gstNo)) {
            formIsValid = false;
            _errors["gstNo"] = "Please enter a valid GST number";
        }
        setErrors(_errors)
        return formIsValid;

    }

    const handleYearData = async () => {
        setloader(true)
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`user/paymentConfirmation`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });

            if (response.status === 200) {
                const result = response.data;

                if (result?.status === 200) {
                    setloader(false)
                    setallData(result.data);
                } else {
                    setloader(false)
                    console.error("API error:", result);
                }
            } else {
                setloader(false)
                console.error("HTTP error:", response);
            }
        } catch (err) {
            setloader(false)
            console.error("Failed to fetch data:", err);
            toast.error('Failed to fetch data');
        }
    };


    useEffect(() => {
        handleYearData()
    }, [])
  
    const submitForm = async () => {

        if (data?.User?.id && data?.User?.price && data?.User?.mobile_no &&  data?.User?.name) {
            const requestData = {
                "userId": data?.User?.id.toString(),
                "amount": data?.User?.price,
                "phone": data?.User?.mobile_no,
                "name": data?.User?.name,
            };

            try {
                const response = await PAYMENTAPi(requestData);

                if (response?.status === 200) {
                    window.open(response?.data?.url);
                }
                else {

                }

            } catch (err) {
                // Handle errors
            }


        }

    }

    return (
        <div>
            <div className='signup'>
                <header className='p-8 flex justify-between'>
                    <div>
                        <img src={logoIcon} alt="" />
                    </div>

                </header>
                <hr />


                <form action="">
                    <Loader loader={loader} />
                    <div className='pt-16 flex justify-center'>
                        <div className='sign-up-page px-4'>


                            <h3 className='pb-10'>Billing Details</h3>
                            <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-x-20 gap-y-4' >
                                <div className='grid  gap-y-4'>

                                    <div className='grid  gap-y-4'>
                                        <div>
                                            <p className='mb-3'>Contact information</p>
                                        </div>
                                        <div>
                                            <p className='mb-3'>E-mail</p>
                                            <input type="text" value={allData?.email} name="name" placeholder='Enter name' className='p-2.5' disabled />

                                        </div>

                                        <div>
                                            <p className='mb-3'>Phone</p>
                                            <input type="text" placeholder='Phone' className='p-2.5 ' name='Phone' value={allData.mobile_no} required disabled />
                                        </div>
                                    </div>

                                    <div className='grid  gap-y-4'>
                                        <div>
                                            <p className='mb-3'> Office Details</p>
                                        </div>
                                        <div>
                                            <p className='mb-3'>Comapany Name</p>
                                            <input type="text" value={allData?.company_name} name="name" placeholder='company_name' className='p-2.5' disabled />

                                        </div>

                                        <div>
                                            <p className='mb-3'>Comapany Type</p>
                                            <input type="text" placeholder='company_type' className='p-2.5 ' name='company_type' value={allData.company_type} required disabled />
                                        </div>

                                    </div>
                                    <div className='grid  gap-y-4'>
                                        <div>
                                            <p className='mb-3'> Billing address </p>
                                        </div>
                                        <div>
                                            <p className='mb-3'>Full name</p>
                                            <input type="text" value={allData?.name} name="name" placeholder='Enter name' className='p-2.5' disabled />

                                        </div>

                                        <div>
                                            <p className='mb-3'>Address</p>
                                            <input type="text" placeholder='address' className='p-2.5 ' name='Phone' value={allData.address} required disabled />
                                        </div>

                                    </div>



                                </div>

                                <div className='shipping_'>
                                    <div className='p-5 px-7'>
                                        <div>
                                            <div className='flex justify-between mb-3'>
                                                <div>
                                                    <p className='mb-3'>Plan Name</p>
                                                </div>
                                                <div>
                                                    <p className='mb-3'>{data?.User?.price === 0 ? "Plan 1" : " Plan 2"}</p>
                                                </div>
                                            </div>
                                            <div className='flex justify-between mb-3'>
                                                <div>
                                                    <p className='mb-3'>Plan Features</p>
                                                </div>
                                                <div>
                                                    {
                                                        data?.User?.plan_type === 0 ? (
                                                            <ul>
                                                                <li>Scope 1</li>
                                                                <li>Scope 2</li>
                                                            </ul>
                                                        ) : (
                                                            <ul>
                                                                <li>Scope 1</li>
                                                                <li>Scope 2</li>
                                                                <li>Scope 3</li>
                                                            </ul>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cus_p'>
                                            <div className='hr_line'>
                                                <hr />
                                            </div>
                                            <div className='flex justify-between'>
                                                <div>
                                                    <p className='mb-3'>Total</p>
                                                </div>
                                                <div>
                                                    <p>{data?.User?.price}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>















                            </div>
                            <div className='mt-12 pt-12 mb-5'>
                                <div className='flex items-center justify-end'>
                                    {/* <button type='button' className='back-btn mr-4' onClick={() => prevStep()}>Back</button> */}
                                    <button type='button' className='save-continue-btn' onClick={() => submitForm()}>Proceed To Payment</button>
                                </div>

                            </div>


                        </div>

                    </div>
                </form>



            </div>

        </div>



    )
}

export default DirectBilling
