import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, GETSCOPEOPTIONDATA, deleteAsssessmentThree, getAsssessmentThree } from '../../../../Utils/services';
import AutoSuggestion from "../../AutoSuggestion"
import DeleteConfirm from "../../DeleteConfirm";
import AuthContext from '../../../../Utils/auth-context';
import Date from "../../Date"


const GoodServiceTable = ({ month, YearData, CreatOn, manthFilter, setloader, monYear }) => {
    const [fuelDetails, setFuelDetails] = useState([]);
    const [errors, setErrors] = useState({});
    const [editIndex, setEditIndex] = useState(-1);
    const [options, setOptions] = useState([]);
    const { plant } = useContext(AuthContext)
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { invoice_date: "", good_name: "", quantity: '', plant_type: plant, category: "Purchased Capital Goods", assessment_month: month, assessment_year: year_data, created_on: CreatOn, unit: '', emission_value: '' }]);
    }

    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];    
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };



    const updateDateInvoice = (index, invoice_date) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].invoice_date = invoice_date
        setFuelDetails(newFuelDetails)

    }

    const updateNameOfMaterial = (index, good_name) => {
        let filter_data = options?.filter((item) => item.label === good_name);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].good_name = good_name
        setFuelDetails(newFuelDetails)

    }

    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};
            if (!fuel.invoice_date) {
                formIsValid = false;
                errors.invoice_date = 'Please enter the date of invoice.';

            }
            if (!fuel.good_name) {
                formIsValid = false;
                errors.good_name = 'Please enter the name of the services';
            }
            else if (!isNaN(fuel.good_name)) {
                formIsValid = false;
                errors.good_name = 'name of the services must be a string.';
            }
            if (!fuel.quantity && fuel.quantity !== 0) {
                formIsValid = false;
                errors.quantity = 'Please enter the quantity.';
            } else if (isNaN(fuel.quantity)) {
                formIsValid = false;
                errors.quantity = 'Quantity must be a number.';
            }
            else if (fuel.quantity < 0) {
                formIsValid = false;
                errors.quantity = 'Quantity must be greater than -1';
            }
            return errors;
        });

        setErrors(newErrors);
        return formIsValid;
    };
    const updateQuantity = (index, quantity) => {
        let filter_data = options?.filter(item => item.label == fuelDetails[index].fuel_type && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].quantity = quantity;
        newFuelDetails[index].scope_data = filter_data[0]?._value
        newFuelDetails[index].emission_value = filter_data[0]?.value 
        setFuelDetails(newFuelDetails)
    }
    const updateNameOfChemical = (index, fuel_type) => {
        let filter_data = options.filter((item) => item.id === fuel_type);
        const newFuelDetails = [...fuelDetails];
        newFuelDetails[index].fuel_type = filter_data[0]?.label
        newFuelDetails[index].unit = filter_data[0]?.unit
     
        setFuelDetails(newFuelDetails)
    }
    const SaveData = async () => {

        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    scopes3: fuelDetails,
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }

    };

    const allAssessment = async () => {
        setloader(true);
        const yer_data =  localStorage.getItem("form")
        const result = await getAsssessmentThree(
            {
                assessment_year: yer_data,
                plant_type: plant,
                category: "Purchased Capital Goods",
                assessment_month: manthFilter,

            }
        );
        setloader(false)
        let filterData = result?.filter((item) => item.assessment_month === month);
        setFuelDetails(filterData)
    }

    const getSelectData = async () => {
        const result = await GETSCOPEOPTIONDATA(3, 32);
        const datas = result?.res?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));
        setOptions(datas);

    }


    useEffect(() => {
        allAssessment()
        getSelectData();
    }, [month, plant])


    return (
        <div >
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Date of Invoice </th>
                            <th>Name of the Goods/Services </th>
                          
                            <th>Type of Capital Good</th>
                     
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails?.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>

                                        <Date index={index} monYear={monYear} handle={updateDateInvoice} value={fuel.invoice_date} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.invoice_date && <span className="error">{hasErrors.invoice_date}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            value={fuel.good_name}
                                            onChange={(event) =>
                                                updateNameOfMaterial(index, event.target.value)
                                            }
                                        />
                                        {/* 
                                        <AutoSuggestion data={options} prevalue={fuel.good_name} updateNameOfChemical={updateNameOfMaterial} index={index} disabled={!isRowEdited} /> */}

                                        {hasErrors && hasErrors.good_name && <span className="error">{hasErrors.good_name}</span>}
                                    </td>
                                    <td>
                                        <AutoSuggestion data={options} prevalue={fuel.fuel_type} updateNameOfChemical={updateNameOfChemical} index={index} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.fuel_type && <span className="error">{hasErrors.fuel_type}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            value={fuel.quantity}
                                            onChange={(event) =>
                                                updateQuantity(index, event.target.value)
                                            }
                                        />
                                        {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                       
                                        {hasErrors && hasErrors.quantity && <span className="error">{hasErrors.quantity}</span>}
                                    </td>
                                  
                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            );
                        })}


                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GoodServiceTable