import axios from "axios";
import { REFRESH_TOKEN } from "./routes";

const NetworkServices = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      if (config.url !== "/refreshToken" && config.url !== "login") {
        const access = localStorage.getItem("token");
        if (access) {
          config.headers["Authorization"] = "Bearer " + access;
        }

        return config;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // // Add a response interceptor
  // axios.interceptors.response.use(
  //   function (response) {
  //     return response;
  //   },

  //   async function (error) {
  //     const originalRequest = error.config;
  //     const refresh = localStorage.getItem("refreshToken");
  //     if (error.response) {
  //       // Catching axios errors
  //       if (error.response.data) {
  //         //catches if the session ended!

  //         if (error?.response?.data?.status === 401) {
  //           originalRequest._retry = true;
  //           const data = {
  //             refreshToken: refresh,
  //             email: "mukul@wearetechtonic.com",
  //             isEmployee: true,
  //           };
  //           const response = await axios.post(REFRESHTOKEN, data);
  //           window.localStorage.setItem("token", response.accessToken);
  //           axios.defaults.headers.common[
  //             "Authorization"
  //           ] = `Bearer ${response.accessToken}`;
  //           return axios(originalRequest);
  //         }
  //       }
  //     }
  //     return Promise.reject(error);
  //   }
  // );
  // axios.interceptors.response.use(
  //   function (response) {
  //     return response;
  //   },
  //   async function (error) {
  //     const originalRequest = error.config;
  //     const refresh = localStorage.getItem("refreshToken");

  //     if (error.response) {
  //       // Catching axios errors
  //       if (error.response.data) {
  //         const customData = JSON.parse(localStorage.getItem("user"));
  //         //catches if the session ended!
  //         if (error?.response?.data?.status === 401) {
  //           // old
  //           originalRequest._retry = true;
  //           const Maker = {
  //             refreshToken: refresh,
  //           };
  //           const response = await axios.post(REFRESH_TOKEN, Maker);

  //           if (response.status === 200) {
  //             axios.defaults.headers.common[
  //               "Authorization"
  //             ] = `Bearer ${response?.data?.accessToken}`;
  //             window.localStorage.setItem(
  //               "access_token",
  //               response.data.accessToken
  //             );
  //             window.localStorage.setItem(
  //               "accessTokenValidity",
  //               response.data.accessTokenValidity
  //             );
  //             window.localStorage.setItem(
  //               "refreshToken",
  //               response.data.refreshToken
  //             );
  //           }
  //           if (response.data.httpStatus.includes("FORBIDDEN")) {
  //             localStorage.removeItem("token");
  //             localStorage.clear();
  //             window.location.replace("/login");
  //           }

  //           return axios(originalRequest);
  //         }
  //       }
  //     }
  //     return Promise.reject(error);
  //   }
  // );
};
export default NetworkServices;
