import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import CoolingPlanttable from '../../../Components/Common/ScopTwoPlantTable/cooling/CoolingPlanttable'
import SteamPlantTable from '../../../Components/Common/ScopTwoPlantTable/Steam/SteamPlantTable'

const Cooling = () => {


    return (
        <div>
            <InnerHeader  innertitle="Purchased Refrigeration" title="Scope 2"/>
            <div className='px-16'>
                <Backbtn title="Purchased Refrigeration" />
                <Descriptions discrip="" />
                <InnerTabe />
                <CoolingPlanttable />
            </div>
        </div>
    )
}

export default Cooling;