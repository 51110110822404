import React, { useContext } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import enscopelogo from "../../Assets/images/sidenav/new_logo.png"
import homeIcon from "../../Assets/images/sidenav/home.svg"
import newAssment from "../../Assets/images/sidenav/content_paste.svg"
import pastAssment from "../../Assets/images/sidenav/content_paste_search.svg"
import report from "../../Assets/images/sidenav/lab_profile.svg"
import certificate from "../../Assets/images/sidenav/ansurance.svg"
import chain from "../../Assets/images/sidenav/forklift.svg"
import setting from "../../Assets/images/sidenav/settings_alert.svg"
import Call from "../../Assets/images/sidenav/call.svg"
import logout from "../../Assets/images/sidenav/logout.svg"
import AuthContext from '../../Utils/auth-context';
import { ModalContext } from '../../Context';

const Sidenav = ({ toggle, handletoggle }) => {
    const authCtx = useContext(AuthContext);
    const modalContext = React.useContext(ModalContext);
    const { closeModal } = modalContext;
    const navigate = useNavigate();

    const handlePage = (e) => {
        localStorage.clear()
        navigate('/login')
    }
    const handle = () => {
        closeModal();
        handletoggle();
    }

    return (
        <div className=''>

            <div className='enscope_logo web_visi'>
                <img src={enscopelogo} alt="" />
            </div>
            <div className='mobile_visi'>
                <div className='enscope_logo'>
                    <img src={enscopelogo} alt="" />
                </div>
                <div id='hamburger-1'
                    className={`hamburger ${toggle ? 'is-active' : ''}`}
                    onClick={handletoggle}
                >
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>

                </div>
            </div>
            <div className='' onClick={handle}>
                <ul className="side-menu">

                    <li>

                        <NavLink to={`/`} activeclassname="active" className="subnavlist">
                            <div className="navIcon flex"> <img src={homeIcon} alt="" />  <p className='ml-4'> Home</p>  </div>
                        </NavLink>
                    </li>

                    <li>

                        <NavLink to={`/new-assessment`} activeclassname="active" className="subnavlist">
                            <div className="navIcon flex"> <img src={newAssment} alt="" />  <p className='ml-4'> New Assessment</p>  </div>
                        </NavLink>
                    </li>

                    <li>

                        <NavLink to={`/past-assessment`} activeclassname="active" className="subnavlist">
                            <div className="navIcon flex"> <img src={pastAssment} alt="" />  <p className='ml-4'> Past Assessment</p>  </div>
                        </NavLink>
                    </li>

                    <li>

                        <NavLink to={`/reports`} activeclassname="active" className="subnavlist">
                            <div className="navIcon flex"> <img src={report} alt="" />  <p className='ml-4'> Reports</p>  </div>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to={`/assurance`} activeclassname="active" className="subnavlist">
                            <div className="navIcon flex"> <img src={certificate} alt="" />  <p className='ml-4'>  Assurance Certficates</p>  </div>
                        </NavLink>
                    </li>

                    <li>

                        <NavLink to={`supply-chain`} activeclassname="active" className="subnavlist">
                            <div className="navIcon flex"> <img src={chain} alt="" />  <p className='ml-4'> Supply Chain</p>  </div>
                        </NavLink>
                    </li>
                    <li>

                        <NavLink to={`settings`} activeclassname="active" className="subnavlist">
                            <div className="navIcon flex"> <img src={setting} alt="" />  <p className='ml-4'> Settings</p>  </div>
                        </NavLink>
                    </li>
                    <li>

                        <NavLink to={`contact`} activeclassname="active" className="subnavlist">
                            <div className="navIcon flex"> <img src={Call} alt="" />  <p className='ml-4'> Contact</p>  </div>
                        </NavLink>
                    </li>

                    <li onClick={() => handlePage()}>
                        <NavLink to={`/login`} activeclassname="active" className="subnavlist" onClick={() => handlePage()}>
                            <div className="navIcon flex" onClick={() => handlePage()}> <img src={logout} alt="" />  <p className='ml-4 text-black' onClick={() => handlePage()}> Logout</p>  </div>
                        </NavLink>
                    </li>

                </ul>
            </div>



        </div>
    )
}

export default Sidenav