import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import BfcPlant from '../../../Components/Common/ScopeThreeTables/BfcPlant/BfcPlant'
import SfcPlant from '../../../Components/Common/ScopeThreeTables/SfcPlant/SfcPlant'

const Bfc = () => {
    return (
        <div>
            <InnerHeader  innertitle="Buyer Fuel Consumption" title="Scope 3"/>
            <div className='px-16'>
                <Backbtn title="Buyer Fuel Consumption" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <BfcPlant />
                {/* <GoodServicesPlant /> */}
            </div>
        </div>
    )
}

export default Bfc