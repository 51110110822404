import React, { useEffect } from 'react';
import { useState } from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { sinUpService } from "../../Utils/services";


function GeneralDetails({ formData, errors, handleInputChange, nextStep, handleLoginPage, setErrors, phoneData, setPhoneData, tellyphoneData, setTellyPhoneData, setloader, setFormData, setCurrentStep }) {
    const [isdiabled, setisdiabled] = useState(false);
    const [priceing, setpricing] = useState(2499);

    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;



        if (!formData.gst_no || typeof formData.gst_no !== "string" || !/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/.test(formData.gst_no)) {
            formIsValid = false;
            _errors["gst_no"] = "Please enter a valid GST number";
        }

        if (!formData.name) {
            formIsValid = false;
            _errors["name"] = "Please enter a valid full name";
        }
        if (!isNaN(formData.name)) {
            formIsValid = false;
            _errors["name"] = "Full Name Must be String ";
        }
        if (!formData.designation) {
            formIsValid = false;
            _errors["designation"] = "Please enter a valid designation";
        }
        if (!isNaN(formData.designation)) {
            formIsValid = false;
            _errors["designation"] = "Designation Must be String ";
        }


        if (!phoneData || phoneData.length < 11 || phoneData.length > 13) {
            formIsValid = false;
            _errors["phone"] = "Please enter a valid Phone number";
        }

        if (isNaN(phoneData)) {
            formIsValid = false;
            _errors["phone"] = "Phone Must be Number ";
        }
        // const isValidPhone = phonePattern.test(phoneDataString);

        // if (!isValidPhone) {
        //     formIsValid = false;
        //     _errors["phone"] = "Please enter a valid 10-digit phone number";
        // }


        if (!formData.company_name) {
            formIsValid = false;
            _errors["company_name"] = "Please enter a valid company name";
        }
        if (!isNaN(formData.company_name)) {
            formIsValid = false;
            _errors["company_name"] = "Company Name Must be String ";
        }


        if (!formData.address) {
            formIsValid = false;
            _errors["address"] = "Please enter a valid address";
        }
        if (!formData.gst_no) {
            formIsValid = false;
            _errors["gst_no"] = "Please enter a valid Gst No";
        }

        if (!isNaN(formData.address)) {
            formIsValid = false;
            _errors["address"] = "Address Type Must be String ";
        }
        if (!tellyphoneData || tellyphoneData.length < 11 || tellyphoneData.length > 13) {
            formIsValid = false;
            _errors["tellyphoneData"] = "Please enter a valid telephone number";
        }

        if (!formData.email) {
            formIsValid = false;
            _errors["email"] = "Please enter a valid email address";
        }
        if (!isNaN(formData.email)) {
            formIsValid = false;
            _errors["email"] = "Email Type Must be String ";
        }
        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
            formIsValid = false;
            _errors["email"] = "Please enter a valid email address";
        }

        if (!formData.password) {
            formIsValid = false;
            _errors["password"] = "Please enter a valid password";
        }
        if (formData.password.length < 8) {
            formIsValid = false;
            _errors["password"] = "Password must be at least 8 characters long";
        }
        if (!formData.confrimPassword) {
            formIsValid = false;
            _errors["confrimPassword"] = "Please confirm your password";
        } else if (formData.confrimPassword !== formData.password) {
            formIsValid = false;
            _errors["confrimPassword"] = "Passwords do not match";
        }


        setErrors(_errors);
        return formIsValid;
    };
    const handletype = (data) => {
        formData["company_type"] = data.value;
    }
    const handleselect = (data) => {

        formData["plan_type"] = data.value;


    }
    const handleselect2 = (data) => {

        formData["subscription_type"] = data.value;
        formData["price"] = data.price;
        setpricing(data?.price)

    }

    const options = [
        { value: '1', label: ' Scope 1,Scope 2,Cema', },
        { value: '2', label: ' Scope 1,Scope 2, Scope 3,Cema'},

    ]
    const optionLS_TYPE = [
        { value: '0', label: 'Half-yearly', price: 2499 },
        { value: '1', label: 'Yearly', price: 7499 },

    ]
    const companies = [
        { value: 'Private Limited Company', label: 'Private Limited Company' },
        { value: 'Public Ltd Company', label: 'Public Ltd Company' },
        { value: 'Partnership', label: 'Partnership ' },
        { value: 'Limited Liability Partnership (LLP)', label: 'Limited Liability Partnership (LLP) ' }
    ]


    const checkPasswordStrength = (password) => {
        // Custom logic to determine password strength
        let strength = 0;
        if (password.length >= 8) {
            strength++;
        }
        if (/[A-Z]/.test(password)) {
            strength++;
        }
        if (/[a-z]/.test(password)) {
            strength++;
        }
        if (/[0-9]/.test(password)) {
            strength++;
        }
        if (/[^A-Za-z0-9]/.test(password)) {
            strength++;
        }
        return strength;
    };

    const getPasswordStrengthLabel = (strength) => {
        // Custom logic to get password strength label
        if (strength <= 0) {
            return '';
        }
        else if (strength <= 2) {
            return 'Weak password';
        } else if (strength <= 3) {
            return 'Medium password';
        } else {
            return 'Strong password';
        }
    };

    const getPasswordStrengthColor = (strength) => {
        // Custom logic to get password strength color
        if (strength <= 2) {
            return 'red';
        } else if (strength <= 3) {
            return 'orange';
        } else {
            return 'green';
        }
    };

    const passwordStrength = checkPasswordStrength(formData.password);
    const passwordStrengthLabel = getPasswordStrengthLabel(passwordStrength);
    const passwordStrengthColor = getPasswordStrengthColor(passwordStrength);

    const nextMove = async () => {
        if (true) {

            setisdiabled(true);
            const digitsToRemove = 2;
            const upphoneNumber = phoneData?.substring(digitsToRemove);
            const uptellyphoneData = tellyphoneData?.substring(digitsToRemove);

            try {
                const data = {
                    "name": formData.name,
                    "email": formData.email,
                    "password": formData.password,
                    "confrimPassword": formData.confrimPassword,
                    "company_name": formData.company_name,
                    "company_type": formData.company_type ? formData.company_type : companies[0].value,
                    "mobile_no": upphoneNumber,
                    "telephone_no": uptellyphoneData,
                    "designation": formData.designation,
                    "address": formData.address,
                    "plan_type": formData.plan_type ? Number(formData.plan_type) : Number(options[0].value),
                    "gst_no": formData.gst_no,
                    "IsAdmin": 0,
                    "step": 1,
                    'payment_status': 0,
                    'subscription_type': formData.plan_type ? Number(formData.subscription_type) : Number(optionLS_TYPE[0].value),
                    'price': formData.price,
                    "userType": 1,
                    "isEmailVerified": false,
                    "verificationCode": 0,
                    "refresh_token": "test",

                    "street_address":
                        "p",

                    "city":
                        "p",

                    "state":
                        "p",

                    "zip_code":
                        "p",

                    "country":
                        "p",


                }


                setloader(true);
                const result = await sinUpService(data);

                setloader(false);

                if (result.status === 200) {
                    setisdiabled(false);
                    localStorage.setItem('email', result?.data?.user?.email);
                    setFormData(prev => ({
                        ...prev,
                        ...result
                    }));

                    toast.success(result.message || "User Sign up Successfully !");
                    setCurrentStep(result?.data?.user?.step)
                } else {
                    toast.error(result.message);
                }
            } catch (error) {
                setloader(false);
                toast.error(error.message || "An error occurred");
            }
        }
    };

    return (
        <div>

            <h3 className='pb-10'>Sign Up</h3>
            <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-x-20 gap-y-4' >
                <div>
                    <p className='mb-3'>Full Name</p>
                    <input type="text" value={formData.name} name="name" placeholder='Enter name' className='p-2.5' onChange={handleInputChange} required />
                    {
                        errors.name && <p className="text-red-500">{errors.name}</p>
                    }
                </div>
                <div>
                    <p className='mb-3'>Designation</p>
                    <input type="text" name='designation' value={formData.designation} placeholder='Enter your designation' className='p-2.5' onChange={handleInputChange} required />
                    {
                        errors.designation && <p className="text-red-500">{errors.designation}</p>
                    }
                </div>

                <div>
                    <p className='mb-3'>Mobile Number</p>
                    <PhoneInput placeholder="Phone Number" country={"in"}
                        value={phoneData}
                        onChange={phone => setPhoneData(phone)}
                        regions={['asia']}
                        required
                        countryCodeEditable={false}

                    />
                    {
                        errors.phone && <p className="text-red-500">{errors.phone}</p>

                    }

                </div>
                <div>
                    <p className='mb-3'>Company Name</p>
                    <input type="text" name="company_name" value={formData.company_name} placeholder='Enter your company name' className='p-2.5' onChange={handleInputChange} required />
                    {
                        errors.company_name && <p className="text-red-500">{errors.company_name}</p>

                    }
                </div>
                <div>
                    <p className='mb-3'>Type of Company</p>
                    <Select
                        options={companies}
                        name="company_type"
                        onChange={handletype}
                        defaultValue={companies[0]}

                    />
                    {
                        errors.company_type && <p className="text-red-500">{errors.company_type}</p>
                    }

                </div>

                <div>
                    <p className='mb-3'>Address</p>
                    <input type="address" placeholder='Enter address' className='p-2.5' name='address' value={formData.address} onChange={handleInputChange} required />
                    {
                        errors.address && <p className="text-red-500">{errors.address}</p>
                    }
                </div>
                <div>
                    <p className='mb-3'>Telephone Number</p>
                    <PhoneInput

                        placeholder="phoneNumber"
                        country={"in"}
                        value={tellyphoneData}
                        onChange={phone => setTellyPhoneData(phone)}
                        regions={['asia']}
                        countryCodeEditable={false}
                        required
                    />
                    {
                        errors.tellyphoneData && <p className="text-red-500">{errors.tellyphoneData}</p>
                    }
                </div>
                <div>
                    <p className='mb-3'>Email Address</p>
                    <input type="email" placeholder='Enter your email id' className='p-2.5' name='email' value={formData?.email} onChange={handleInputChange} required autocomplete="off" />
                    {
                        errors.email && <p className="text-red-500">{errors.email}</p>
                    }
                </div>
                <div>
                    <p className='mb-3'>Password</p>
                    <input type="Password" placeholder='Enter password' className='p-2.5' name='password' value={formData?.password} onChange={handleInputChange} required autocomplete="off" />
                    {
                        errors.password && <p className="text-red-500">{errors.password}</p>
                    }

                    <span style={{ color: passwordStrengthColor }} className="mr-4">
                        {passwordStrengthLabel}
                    </span>

                </div>

                <div>
                    <p className='mb-3'>Confirm Password</p>
                    <input type="Password" placeholder='Enter password' className='p-2.5' name='confrimPassword' value={formData?.confrimPassword} onChange={handleInputChange} required autocomplete="off" />
                    {
                        errors.confrimPassword && <p className="text-red-500">{errors.confrimPassword}</p>
                    }
                </div>
                <div>
                    <p className='mb-3'>Select Plan</p>
                    <Select
                        options={options}
                        defaultValue={options[0]}
                        name="plan_type"
                        onChange={handleselect}

                    />
                </div>
                <div>
                    <p className='mb-3'>Select Subscription Type</p>
                    <Select
                        options={optionLS_TYPE}
                        defaultValue={optionLS_TYPE[0]}
                        name="subscription_type"
                        onChange={handleselect2}

                    />
                </div>
                <div>
                    <p className='mb-3'>Gst No</p>
                    <input type="text" placeholder='Enter Gst No' className='p-2.5 autosuggest' name='gst_no' value={formData.gst_no} onChange={handleInputChange} required />
                    {
                        errors.gst_no && <p className="text-red-500">{errors.gst_no}</p>
                    }
                </div>
                <div>
                    <p className='mb-3'>Price</p>
                    <input
                        type="text"
                        disabled
                        className='p-2.5 autosuggest'
                        name='price'
                        value={priceing}

                    />
                </div>



            </div>
            <div className='mt-12'>
                <button type='button' className='save-continue-btn' onClick={() => nextMove()}  >Save & continue</button>
                <p className='py-4'>Already have an account? <span className='login-link cursor-pointer' onClick={handleLoginPage} >Log In</span></p>
            </div>

        </div>
    )
}

export default GeneralDetails