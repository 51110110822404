import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Layouts from "./Layout/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../Utils/auth-context";
import Login from "../Pages/Login";
import SignUp from "../Pages/Sinup";
import AddPlant from "../Pages/PlantDetails";
import ShowPlant from "../Pages/PlantDetails/ShowPlant";
import DirectBilling from "../Pages/Sinup/DirectBilling";
import Conformation from "../Pages/Sinup/Conformation";

const App = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (authCtx.isLoggedIn) {
  //     navigate("/");
  //   }
  //   else
  //   {
  //     navigate("/login");
  //   }
  // }, [authCtx.isLoggedIn]);

  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} />
      {/* <Layouts /> */}

      <Routes>
        <Route exact path="/forgot-password" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/addplant" element={<AddPlant />} />
        <Route path="/view-plant" element={<ShowPlant />} />

        {authCtx.isLoggedIn ? (
          <>
            <Route path="/*" element={<Layouts />} />
            <Route path="/billing" element={<DirectBilling />} />
            <Route path="/bill-confirm" element={<Conformation />} />
          </>
        ) : (
          <Route path="/login" element={<Login />} />
        )}
        <Route
          path="*"
          element={<Navigate to={authCtx.isLoggedIn ? "/" : "/login"} />}
        />
      </Routes>
    </div>
  );
};

export default App;
