import React, { useEffect } from 'react'
import { useState } from 'react'
import { getYear, FINANCIAYEARAPi } from '../../Utils/services'
import PlantTable from './PlantTables/PlantTable';
import Loader from './Loader/index'

const StationaryPlantOne = () => {
    const [plantOne, setPlantData] = useState([]);
    const [loader, setloader] = useState(false);
    let curryear = localStorage.getItem("form");

    const handleYearData = async () => {
        if(curryear)
        {
            setloader(true);
            try {
                let _year = curryear.split('-')[0]
                let result = await FINANCIAYEARAPi(_year);
                if(result?.res)
                {
                    setPlantData(result.res);
                }
                
            } catch (error) {
                setloader(false);
                // Handle the error here, you can log it or show a message to the user
                console.error("Error fetching year data:", error);
            }
            finally {
                setloader(false);
            }
    
        }
    
    }

    useEffect(() => {
        handleYearData()
    }, [])
    return (
        <div className='pt-2'>
            <p className='profile-texts'>Donnelly-Wisozk Die cast manufacturing unit</p>
            <div className='pt-4'>
                <p className='ass-year'>FinancialYear : {curryear}</p>
            </div>
            <Loader loader={loader} />
            {

                plantOne?.map((item) => (
                    <>
                        <p className='pt-4'>{item?.created_on}</p>
                        <PlantTable month={item?.assessment_month} YearData={item?.year} CreatOn={item?.created_on} manthFilter={item?.assessment_month} setloader={setloader} />
                    </>
                ))
            }
        </div>
    )
}

export default StationaryPlantOne