import React from 'react'

export default function index({ loader }) {
    return (
        <div>
            {
                loader && <div className='loadingSpinnerContainer'>
                    <div className='loadingSpinner'></div></div>
            }

        </div>
    )
}
