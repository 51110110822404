import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { addAsssementOne, AddAsssessmentthree, deleteAsssessmentThree, getAsssessmentOne, getAsssessmentThree, GETSCOPEOPTIONDATA } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../DeleteConfirm"
import AutoSuggestion from "../../AutoSuggestion"

const WasteMaterialtable = ({ months, YearData, CreatOn, manthFilter, setloader }) => {
    const [errors, setErrors] = useState({});
    const [fuelDetails, setFuelDetails] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [options, setOptions] = useState([]);
    const { plant } = useContext(AuthContext)
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { subsidiaries_name: "", location: "", month: '', fuel_type: "", fuel_consumed_qty: "", plant_type: plant, category: "", assessment_month: months, assessment_year: year_data, created_on: CreatOn, emission_value: '' , category: "Waste Materials"}]);
    }


    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };

    // const updateType = (index, type_fuel) => {
    //     const newFuelDetails = [...fuelDetails]
    //     newFuelDetails[index].type_fuel = type_fuel
    //     setFuelDetails(newFuelDetails)

    // }

    const updateSubsidiaries = (index, subsidiaries_name) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].subsidiaries_name = subsidiaries_name
        setFuelDetails(newFuelDetails)

    }

    const updateloaction = (index, location) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].location = location
        setFuelDetails(newFuelDetails)

    }



    const updateMonth = (index, month) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].month = month
        setFuelDetails(newFuelDetails)
    }

    const updateFuelType = (index, fuel_type) => {
        let filter_data = options?.filter((item) => item.id === fuel_type);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].fuel_type = filter_data[0]?.label
        setFuelDetails(newFuelDetails)
    }



    const updateQtyFuelConsumption = (index, fuel_consumed_qty) => {
        
        let filter_data = options?.filter(item => item.label == fuelDetails[index].fuel_type && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].emission_value = filter_data[0]?.value ;
        newFuelDetails[index].scope_data = filter_data[0]?._value
        newFuelDetails[index].fuel_consumed_qty = fuel_consumed_qty
        setFuelDetails(newFuelDetails)
    }


    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};
            // if (!fuel.month) {
            //     formIsValid = false;
            //     errors.month = 'Please select a  month.';
            // }

            if (!fuel.subsidiaries_name) {
                formIsValid = false;
                errors.subsidiaries_name = 'Please enter the Name.';
            }
            else if (!isNaN(fuel.subsidiaries_name)) {
                formIsValid = false;
                errors.subsidiaries_name = 'Name must be a string.';
            }

            if (!fuel.location) {
                formIsValid = false;
                errors.location = 'Please enter the Raw Material.';
            }
            else if (!isNaN(fuel.location)) {
                formIsValid = false;
                errors.location = 'Raw Material must be a string.';
            }
            if (!fuel.fuel_type) {
                formIsValid = false;
                errors.fuel_type = 'Please enter the technique.';
            }
            else if (!isNaN(fuel.fuel_type)) {
                formIsValid = false;
                errors.fuel_type = 'technique must be a string.';
            }
            if (!fuel.fuel_consumed_qty && fuel.fuel_consumed_qty !==0 ) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Please enter the Quantity.';
            }
            else if (fuel.fuel_consumed_qty < 0) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Quantity must be a greaterv than 0.';
            }
            else if (isNaN(fuel.fuel_consumed_qty)) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Quantity must be a Number.';
            }

            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };

    const SaveData = async () => {


        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    "scopes3": fuelDetails
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };
    const getSelectData = async () => {
        const result = await GETSCOPEOPTIONDATA(3, 23);
        const datas = result?.res?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));


        setOptions(datas)

    }
    const allAssessment = async (month) => {
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree(
            {
                assessment_year: yaer_data,
                plant_type: plant,
                category: "Waste Materials",
                assessment_month: manthFilter,
            }

        );
       

        let filterData = result?.filter((item) => item.assessment_month === manthFilter);
        // console.log(filterData)
        setFuelDetails(filterData)
    }


    useEffect(() => {
        allAssessment(months)
        getSelectData()
    }, [months, plant])


    return (
        <div >
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name of the Raw Material</th>
                            <th>Type of Raw Material</th>
                            <th>Waste Management Technique</th>
                            <th>Qty of Fuel Consumed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.subsidiaries_name}
                                            onChange={(event) =>
                                                updateSubsidiaries(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.subsidiaries_name && <span className="error">{hasErrors.subsidiaries_name}</span>}
                                    </td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.location}
                                            onChange={(event) =>
                                                updateloaction(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.location && <span className="error">{hasErrors.location}</span>}
                                    </td>
                                    <td>
                                        <AutoSuggestion data={options} prevalue={fuel.fuel_type} updateNameOfChemical={updateFuelType} index={index} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.fuel_type && <span className="error">{hasErrors.fuel_type}</span>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.fuel_consumed_qty}
                                            onChange={(event) =>
                                                updateQtyFuelConsumption(index, event.target.value)
                                            }
                                        />
                                            {fuel.unit && <span className='mx-2'>{fuel.unit}
                                        </span>}
                                        {hasErrors && hasErrors.fuel_consumed_qty && <span className="error">{hasErrors.fuel_consumed_qty}</span>}
                                    </td>



                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default WasteMaterialtable;