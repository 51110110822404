import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import ChemicalandCumbustionplantTwo from '../../../Components/Common/ScopeThreeTables/Chemical-and-gas/ChemicalandCumbustionplantTwo'
import SuppleirLeaseRentPlant from '../../../Components/Common/ScopeThreeTables/supplier_lease_rent/SuppleirLeaseRentPlant'

const BuyLeaseAndRent = () => {

    return (
        <div>
            <InnerHeader  innertitle="Supplier Leased Assests" title="Scope 3"/>
            <div className='px-16'>
                <Backbtn title="Supplier Leased Assests" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */} 
                <SuppleirLeaseRentPlant />
            </div>
        </div>
    )
}

export default BuyLeaseAndRent 