import React, { useState, useEffect, useContext } from 'react';
import { PlantView } from "../../Utils/services";

import AuthContext from '../../Utils/auth-context';

const InnerTabe = () => {
    const [data, setData] = useState();
    const authCtx = useContext(AuthContext);

    const asingment = async () => {
        const result = await PlantView();
        const filteredData = result.res.filter(item => item.status === 1);
        setData(filteredData)
        authCtx.setplant(filteredData[0]?.id)

    }
    useEffect(() => {
        asingment()
    }, [])
    return (
        <div className='flex items-center tabebtn'>

            {
                data && data?.map((val) => {
                    return (
                        <>
                            <div className={`pr-4 cursor-pointer  ${val.id === authCtx.plant && "active-settings"} `} onClick={() => authCtx.setplant(val.id)}>
                                <p className=''>{val?.name}</p>
                            </div>
                        </>
                    )
                }
                )
            }


        </div>
    )
}

export default InnerTabe