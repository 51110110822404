import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AddAsssessmentthree, deleteAsssessmentThree, getAsssessmentThree, GETSCOPEOPTIONDATA } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';
import DeleteConfirm from "../../DeleteConfirm";
import AutoSuggestion from "../../AutoSuggestion"

const Wfc_table = ({ months, YearData, CreatOn, manthFilter, setloader }) => {
    const [fuelDetails, setFuelDetails] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [options, setOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const { plant } = useContext(AuthContext)
    const year_data = localStorage.getItem("form")
    const addRow = () => {
        setFuelDetails([...fuelDetails, { warehouse_name: "", location: "", month: "", fuel_type: "", fuel_consumed_qty: "", plant_type: plant, category: "Warehouse Electricity Consumption", assessment_month: months, assessment_year: year_data, created_on: CreatOn, unit: '', emission_value: '' }]);
    }


    const handleDeleteConfirm = async (index, id) => {
        try {
            if (id) {
                await deleteAsssessmentThree(id);
            }
            const newFuelDetails = [...fuelDetails];
            newFuelDetails.splice(index, 1);
            setFuelDetails(newFuelDetails);
        } catch (error) {
            console.error(error);
        }
    };

    const updateNameOfwarehouse = (index, warehouse_name) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].warehouse_name = warehouse_name
        setFuelDetails(newFuelDetails)

    }
    const updateFueltype = (index, fuel_type) => {
        let filter_data = options?.filter((item) => item.id === fuel_type);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].fuel_type = filter_data[0]?.label
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].emission_value = filter_data[0]?.value
        newFuelDetails[index].scope_data = filter_data[0]?._value
        setFuelDetails(newFuelDetails)
    }
    const updateLocation = (index, location) => {
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].location = location
        setFuelDetails(newFuelDetails)

    }


    // const updateDateMonth = (index, month) => {
    //     const newFuelDetails = [...fuelDetails]
    //     newFuelDetails[index].month = month
    //     setFuelDetails(newFuelDetails)
    // }


    const updateFuelType = (index, fuel_type) => {
        let filter_data = options?.filter((item) => item.id === fuel_type);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].unit = filter_data[0]?.unit ? filter_data[0]?.unit : "";
        newFuelDetails[index].fuel_type = filter_data[0]?.label
        setFuelDetails(newFuelDetails)
    }




    const updateFuelConsumedQty = (index, fuel_consumed_qty) => {
        let filter_data = options?.filter(item => item.label == fuelDetails[index].fuel_type && item.unit == fuelDetails[index].unit);
        const newFuelDetails = [...fuelDetails]
        newFuelDetails[index].emission_value = filter_data[0]?.value;
        newFuelDetails[index].scope_data = filter_data[0]?._value
        newFuelDetails[index].fuel_consumed_qty = fuel_consumed_qty
        setFuelDetails(newFuelDetails)
    }

    const validateFields = () => {
        let formIsValid = true;
        const newErrors = fuelDetails.map((fuel) => {
            const errors = {};
            if (!fuel.warehouse_name) {
                formIsValid = false;
                errors.warehouse_name = 'Please enter the Warhouse Name.';
            }
            if (!isNaN(fuel.warehouse_name)) {
                formIsValid = false;
                errors.warehouse_name = 'warehouse Name must be a string.';
            }
            if (!fuel.location) {
                formIsValid = false;
                errors.location = 'Please enter the Location Name.';
            }
            if (!isNaN(fuel.location)) {
                formIsValid = false;
                errors.location = 'Loaction must be a string.';
            }
            if (!isNaN(fuel.fuel_type)) {
                formIsValid = false;
                errors.fuel_type = 'fuel type must be a string.';
            }
            if (!fuel.fuel_type) {
                formIsValid = false;
                errors.fuel_type = 'Please enter the fuel type.';
            }
            if (!fuel.fuel_consumed_qty) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Please enter the Quantity.';
            }
            if (isNaN(fuel.fuel_consumed_qty)) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Quantity must be a number.';
            }
            if (fuel.fuel_consumed_qty < 0) {
                formIsValid = false;
                errors.fuel_consumed_qty = 'Quantity must be a greater than -1.';
            }
            return errors;

        });

        setErrors(newErrors);
        return formIsValid;
    };

    const SaveData = async () => {

        if (validateFields()) {
            try {
                setloader(true)
                const result = await AddAsssessmentthree({
                    scopes3: fuelDetails,
                });
                setloader(false)
                toast.success(result.message || 'successfully add and update');
                allAssessment();
                setEditIndex(-1);

            }
            catch (error) {
                setloader(false)
                toast.error(error.message);
            }
        }



    };
    const getSelectData = async () => {
        setloader(true)
        const result = await GETSCOPEOPTIONDATA(3, 16);

        const datas = result?.res?.map((item) => ({
            id: item?.id,
            label: item?.name,
            unit: item?.unit,
            value: item?.value,
            _value: item?.scope_data,
        }));
        setOptions(datas);
        setloader(false)

    }

    const allAssessment = async (month) => {
        setloader(true);
        const yaer_data = localStorage.getItem('form')
        const result = await getAsssessmentThree(
            {
                assessment_year: yaer_data,
                plant_type: plant,
                category: "Warehouse Electricity Consumption    ",
                assessment_month: manthFilter,

            }

        );

        let filterData = result?.filter((item) => item.assessment_month === manthFilter);
        // console.log(filterData)
        setFuelDetails(filterData);
        setloader(false);
    }


    useEffect(() => {
        allAssessment(months)
        getSelectData();
    }, [months, plant])


    return (
        <div >
            <div className='plant-table mt-4'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name of Warehouse</th>
                            <th>Location</th>
                            {/* <th>Month</th> */}
                            {/* <th>Fuel Type</th> */}
                       
                            <th>Unit</th>
                            <th>Qty of Electricity  Consumed</th>
                            {/* <th></th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fuelDetails.map((fuel, index) => {
                            const isRowEdited = index === editIndex || !fuel.id;
                            const hasErrors = errors[index];
                            const isQuantityDisabled = !isRowEdited || !fuel.fuel_type
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.warehouse_name}
                                            onChange={(event) =>
                                                updateNameOfwarehouse(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.warehouse_name && <span className="error">{hasErrors.warehouse_name}</span>}
                                    </td>

                                    <td>
                                        <input
                                            type='text'
                                            disabled={!isRowEdited}
                                            value={fuel.location}
                                            onChange={(event) =>
                                                updateLocation(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.location && <span className="error">{hasErrors.location}</span>}
                                    </td>
                                    {/* <td>
                                        <input
                                            type='text'
                                            value={fuel.month}
                                            onChange={(event) =>
                                                updateDateMonth(index, event.target.value)
                                            }
                                        />
                                        {hasErrors && hasErrors.fuel_type && <span className="error">{hasErrors.fuel_type}</span>}
                                    </td> */}
                                    {/* <td>
                                        <AutoSuggestion data={options} prevalue={fuel.fuel_type} updateNameOfChemical={updateFuelType} index={index} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.fuel_type && <span className="error">{hasErrors.fuel_type}</span>}
                                    </td> */}
                                    <td>
                                        <AutoSuggestion data={options} prevalue={fuel.fuel_type} updateNameOfChemical={updateFueltype} index={index} disabled={!isRowEdited} />

                                        {hasErrors && hasErrors.fuel_type && <div className="error">{hasErrors.fuel_type}</div>}
                                    </td>
                                    <td>
                                        <input
                                            type='text'

                                            value={fuel.fuel_consumed_qty}
                                            onChange={(event) =>
                                                updateFuelConsumedQty(index, event.target.value)
                                            }
                                        />

                                        {hasErrors && hasErrors.fuel_consumed_qty && <span className="error">{hasErrors.fuel_consumed_qty}</span>}
                                    </td>


                                    <td className='flex justify-end'>
                                        {!isRowEdited && (
                                            <button className='delbtn' onClick={() => setEditIndex(index)}>
                                                Edit
                                            </button>
                                        )}

                                        {isRowEdited && (
                                            <>
                                                <button className='delbtn mx-2' onClick={SaveData}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                        <DeleteConfirm onDelete={() => handleDeleteConfirm(index, fuel.id)} />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
                <div className='w-full flex justify-end tfooter p-3'>
                    <button className='addbtn' onClick={addRow}>
                        Add Row
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Wfc_table;