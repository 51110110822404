import React from 'react';
import Swal from 'sweetalert2';

const index = ({ onDelete, onCancel }) => {
    const handleDelete = async () => {
        const result = await Swal.fire({
            title: 'Delete Item',
            text: 'Are you sure you want to delete this item?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
        });

        if (result.isConfirmed) {
            onDelete();
        } else {
            Swal.close()
        }
    };

    return (
        <button className='mx-2 delbtn' onClick={handleDelete}>Delete</button>
    );
};

export default index;