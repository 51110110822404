import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import Wecplant from '../../../Components/Common/ScopeThreeTables/wirehouseelec/Wecplant'

const Wec = () => {


    return (
        <div>
            <InnerHeader   innertitle="Warehouse Electricity Consumption" title="Scope 3"  />
            <div className='px-16'>
                <Backbtn title="Warehouse Electricity Consumption" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <Wecplant />

            </div>

        </div>
    )
}

export default Wec