import React, { useState } from "react";

const AuthContext = React.createContext({
  isSuperAdmin: false,
  token: "",
  refreshToken: "",
  isLoggedIn: false,
  login: (token, refreshToken) => {},
  logout: () => {},
  setplant: (data) => {},
  handle: (data) => {},
  plant: "",
  toogle: true,
  setToogle: () => {}, // Add setToogle with a default empty function
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const initialRefreshToken = localStorage.getItem("refreshToken");
  const [token, setToken] = useState(initialToken);
  const [plantType, setPlantType] = useState(0);
  const [toogle, setToogle] = useState(true);
  const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
  const userIsLoggedIn = !!token;

  const loginHandler = (token, refreshToken, isSuperAdmin) => {
    setToken(token);
    setRefreshToken(refreshToken);
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
  };
  const logoutHandler = () => {
    setToken(null);
    setRefreshToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("form");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("ALLDATA");
    localStorage.removeItem("userID");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isSuperAdmin");
  };
  const setplant = (data) => {
    setPlantType(data);
  };
  const handle = () => {
    setToogle(!toogle);
  };
  const contextValue = {
    token: token,
    refreshToken: refreshToken,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    setplant: setplant,
    plant: plantType,
    handle: handle,
    toogle: toogle,
    setToogle: setToogle, // Include setToogle in the context
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
