import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'
import BuyleaseRentPlant from '../../../Components/Common/ScopeThreeTables/buy_lease_rent/BuyleaseRentPlant'


const   BuyleaseAndRenttwo = () => {

    return (
        <div>
            <InnerHeader innertitle="Buyer Leased Assets" title="Scope 3" />
            <div className='px-16'>
                <Backbtn title="Buyer Leased Assets" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <BuyleaseRentPlant />
            </div>
        </div>
    )
}

export default BuyleaseAndRenttwo