import React, { useEffect, useState } from 'react'
import { getYear } from '../../Utils/services'
import ProcessEmissionTable from './PlantTables/ProcessEmissionTable';
import Loader from './Loader/index'

const ProcessEmissionPlant = () => {
    const [editid, seteditid] = useState(0);
    const [plant, setPlantData] = useState([]);
    const [isAdding, setIsAdding] = useState(true);
    const [loader, setloader] = useState(false);
    let curryear = localStorage.getItem("form");
    const handleYearData = async () => {

        let result = await getYear()
        setPlantData(result.res.data)

    }

    useEffect(() => {
        handleYearData()
    }, [])



    return (
        <div className='pt-2'>
            <p className='profile-texts'>Donnelly-Wisozk Die cast manufacturing unit</p>
            <div className='pt-4'>
                <p className='ass-year'>FinancialYear : {curryear}</p>
            </div>
            <Loader loader={loader}/>
            {
                plant?.map((item) => (
                    <>
                        <p className='pt-4'>{item?.created_on}</p>
                        <ProcessEmissionTable month={item?.assessment_month} YearData={item?.year} CreatOn={item?.created_on} manthFilter={item?.assessment_month} setloader={setloader} monYear={item?.created_on} />
                    </>
                ))
            }
        </div>
    )
}

export default ProcessEmissionPlant