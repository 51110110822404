import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Cema from '../../Pages/NewAssessment/CEMA';
import ScopeOne from '../../Pages/NewAssessment/Scope_1';
import ProcessEmission from '../../Pages/NewAssessment/Scope_1/ProcessEmission';
import ChemicalAndCumbustion from '../../Pages/NewAssessment/Scope_1/ChemicalAndCumbustion';
import MobileCombustion from '../../Pages/NewAssessment/Scope_1/MobileCombustion';
import StationayCombustion from '../../Pages/NewAssessment/Scope_1/StationayCombustion';
import ScopeTwo from '../../Pages/NewAssessment/Scope_2';
import Cooling from '../../Pages/NewAssessment/Scope_2/Cooling';
import Electricity from '../../Pages/NewAssessment/Scope_2/Electricity';
import Steam_Heat from '../../Pages/NewAssessment/Scope_2/Steam_Heat';
import ScopeThree from '../../Pages/NewAssessment/Scope_3';
import Bec from '../../Pages/NewAssessment/Scope_3/Bec';
import Bfc from '../../Pages/NewAssessment/Scope_3/Bfc';
import BusinessTravel from '../../Pages/NewAssessment/Scope_3/BusinessTravel';
import BuyLeaseAndRent from '../../Pages/NewAssessment/Scope_3/BuyLeaseAndRent';
import BuyleaseAndRenttwo from '../../Pages/NewAssessment/Scope_3/BuyleaseAndRenttwo';
import ChemicalAndCumbustionTwo from '../../Pages/NewAssessment/Scope_3/ChemicalAndCumbustionTwo';
import EmployeeCommuting from '../../Pages/NewAssessment/Scope_3/EmployeeCommuting';
import Fec from '../../Pages/NewAssessment/Scope_3/Fec';
import Ffc from '../../Pages/NewAssessment/Scope_3/Ffc';
import GoodsandServices from '../../Pages/NewAssessment/Scope_3/GoodsandServices';
import InboundTransport from '../../Pages/NewAssessment/Scope_3/InboundTransport';
import OutboundTransport from '../../Pages/NewAssessment/Scope_3/OutboundTransport';
import RowMaterial from '../../Pages/NewAssessment/Scope_3/RowMaterial';
import Sec from '../../Pages/NewAssessment/Scope_3/Sec';
import Sfc from '../../Pages/NewAssessment/Scope_3/Sfc';
import SoldProduct from '../../Pages/NewAssessment/Scope_3/SoldProduct';
import SoldProductProcess from '../../Pages/NewAssessment/Scope_3/ProcessSold';
import SubsidiaryElectricConsm from '../../Pages/NewAssessment/Scope_3/SubsidiaryElectricConsm';
import SubsidiaryFuelConsumption from '../../Pages/NewAssessment/Scope_3/SubsidiaryFuelConsumption';
import WasteMaterial from '../../Pages/NewAssessment/Scope_3/WasteMaterial';
import Wec from '../../Pages/NewAssessment/Scope_3/Wec';
import Wfc from '../../Pages/NewAssessment/Scope_3/Wfc';
import Payment from '../../Pages/Payment';
import EndofLife from '../../Pages/NewAssessment/Scope_3/EndofLife';
const HomePages = React.lazy(() => import("../../Pages/Home"));
const PastAssessmentPage = React.lazy(() => import('../../Pages/PastAssessment/index'))
const NewAssessmentPage = React.lazy(() => import('../../Pages/NewAssessment/index'))
const ReportPage = React.lazy(() => import('../../Pages/Report/index'))
const AssuranceCertifacte = React.lazy(() => import('../../Pages/Assurance_Certficates/index'))
const Supplychain = React.lazy(() => import('../../Pages/SupplyChain/index'))
const Setting = React.lazy(() => import('../../Pages/Setting/index'))
const Contact = React.lazy(() => import('../../Pages/Contact'))


const MainWrapper = () => {
    return (
        <div className='main-wraper'>
            <Suspense fallback={"loading..."}>
                <Routes>
                    <Route exact path="/" element={<HomePages />} />
                    <Route exact path="/new-assessment" element={<NewAssessmentPage />} />
                    <Route exact path="/new-assessment/scope-one" element={<ScopeOne />} />
                    <Route exact path="/new-assessment/scope-one/stationary-combustion" element={<StationayCombustion />} />
                    <Route exact path="/new-assessment/scope-one/mobile-combustion" element={<MobileCombustion />} />
                    <Route exact path="/new-assessment/scope-one/chemical-and-gas" element={<ChemicalAndCumbustion />} />
                    <Route exact path="/new-assessment/scope-one/process-emission" element={<ProcessEmission />} />
                    <Route exact path="/new-assessment/scope-two" element={<ScopeTwo />} />
                    <Route exact path="/new-assessment/scope-two/purchased-electricity" element={<Electricity />} />
                    <Route exact path="/new-assessment/scope-two/purchased-heat" element={<Steam_Heat />} />
                    <Route exact path="/new-assessment/scope-two/purchased-refrigeration" element={<Cooling />} />
                    <Route exact path="/new-assessment/scope-three" element={<ScopeThree />} />
                    <Route exact path="/new-assessment/scope-three/purchased-raw-materials" element={<RowMaterial />} />
                    <Route exact path="/new-assessment/scope-three/purchased-chemicals-and-gases" element={<ChemicalAndCumbustionTwo />} />
                    <Route exact path="/new-assessment/scope-three/puchased-capital-goods" element={<GoodsandServices />} />
                    <Route exact path="/new-assessment/scope-three/supplier-fuel-consumption" element={<Sfc />} />
                    <Route exact path="/new-assessment/scope-three/supplier-electricity-consumption" element={<Sec />} />
                    <Route exact path="/new-assessment/scope-three/buyer-fuel-consumption" element={<Bfc />} />
                    <Route exact path="/new-assessment/scope-three/buyer-electricity-consumption" element={<Bec />} />
                    <Route exact path="/new-assessment/scope-three/inbound-transport" element={<InboundTransport />} />
                    <Route exact path="/new-assessment/scope-three/outbound-transport" element={<OutboundTransport />} />
                    <Route exact path="/new-assessment/scope-three/business-travel" element={<BusinessTravel />} />
                    <Route exact path="/new-assessment/scope-three/employee-commuting" element={<EmployeeCommuting />} />
                    <Route exact path="/new-assessment/scope-three/warehouse-fuel-consumption" element={<Wfc />} />
                    <Route exact path="/new-assessment/scope-three/warehouse-electricity-consumption" element={<Wec />} />
                    <Route exact path="/new-assessment/scope-three/supplier-leased-assests" element={<BuyLeaseAndRent />} />
                    <Route exact path="/new-assessment/scope-three/buyer-leased-assets" element={<BuyleaseAndRenttwo />} />
                    <Route exact path="/new-assessment/scope-three/waste-materials" element={<WasteMaterial />} />
                        <Route exact path="/new-assessment/scope-three/end-of-life-treatment-of-sold-products" element={<EndofLife />} />
                    <Route exact path="/new-assessment/scope-three/subsidiary-fuel-consumption" element={<SubsidiaryFuelConsumption />} />
                    <Route exact path="/new-assessment/scope-three/subsidiary-electricity-consumption" element={<SubsidiaryElectricConsm />} />
                    <Route exact path="/new-assessment/scope-three/use-of-sold-product" element={<SoldProduct />} />
                    <Route exact path="/new-assessment/scope-three/processing-of-sold-products" element={<SoldProductProcess />} />
                    <Route exact path="/new-assessment/scope-three/franchise-fuel-consumption" element={<Ffc />} />
                    <Route exact path="/new-assessment/scope-three/franchise-electricity-consumption" element={<Fec />} />
                    <Route exact path="/new-assessment/cema" element={<Cema />} />
                    <Route exact path="/past-assessment" element={<PastAssessmentPage />} />
                    <Route exact path="/reports" element={<ReportPage />} />
                    <Route exact path="/assurance" element={<AssuranceCertifacte />} />
                    <Route exact path="/supply-chain" element={<Supplychain />} />
                    <Route exact path="/settings" element={<Setting />} />
                    <Route exact path="/settings/sc" element={<Setting />} />
                    <Route exact path="/contact" element={<Contact />} />
                    <Route exact path="/payment" element={<Payment />} />

                </Routes>
            </Suspense>
        </div>
    )
}

export default MainWrapper