import React, { useContext, useState } from 'react'
import { ModalContext } from '../../Context';
import stationIcon from "../../Assets/images/icons/local_gas_station.svg"
import info from "../../Assets/images/icons/info.svg"
import busIcon from "../../Assets/images/icons/directions_bus.svg"
import scienceIcon from "../../Assets/images/icons/science.svg"
import { useNavigate } from 'react-router-dom'
import { ViewScopeApi, getYear } from '../../Utils/services';
import Loader from "../../Components/Common/Loader"
import TooltipWrapper from './TooltipWrapper';
import DescModal from './DescModal';
import { toast } from 'react-toastify';


const ScopeLabs = () => {
    const [innerData, setinnerData] = useState();
    const [loader, setLoader] = useState(false)
    const navigation = useNavigate();
    const userId = localStorage.getItem("userID");
    const currYear = localStorage.getItem("form")
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;

    const handleNevigation = (data) => {
        if (data === "Stationary Combustion") {
            navigation("/new-assessment/scope-one/stationary-combustion");
        } else if (data === "Mobile Combustion") {
            navigation("/new-assessment/scope-one/mobile-combustion");
        } else if (data === "Chemicals and Gases Consumption") {
            navigation("/new-assessment/scope-one/chemical-and-gas");
        }
        else if (data == "Process Emissions"  ) {
            navigation("/new-assessment/scope-one/process-emission");
        }

    }

    const getData = async () => {
        if (currYear !== null) {
            setLoader(true)
            const result = await ViewScopeApi(userId, currYear, 1);
            setinnerData(result?.res?.data);
            setLoader(false)
        } else {
            setinnerData([]);
        }
    }

    React.useEffect(() => {
        getData();
    }, []);
    const handleHover = (data) => {
   

        if (data?.description) {
            const Addsupplier = <DescModal allData={data} />

            handleModalData(Addsupplier, "md");
        }
        else {
            toast.warning("No Description is There")
        }



    }
    return (
        <div className='pt-12'>
            <Loader loader={loader} />
            <div className="grid grid-cols-1  xl:grid-cols-3  md:grid-cols-2 gap-4">

                {
                    innerData?.map((val) => (
                        <div onClick={() => handleNevigation(val.product)} className='flex items-center cursor-pointer labs justify-between'>
                            <div className=' flex items-center cursor-pointer' >
                                <img src={stationIcon} alt="" />
                                <span className='px-6'>{val.product}</span>
                            </div>
                            <div onMouseEnter={() => handleHover(val)} >
                                <img src={info} alt="" />
                            </div>

                        </div>


                    ))
                }

            </div>
        </div>
    );
}

export default ScopeLabs