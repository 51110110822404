import React from 'react';
import rigntIcon from "../../Assets/images/icons/chevron_right.svg"

const InnerHeader = ({ title, innertitle }) => {

    return (
        <div className=' bg-white inner-header  flex items-center breadcrumb m-2' >
            <p>New Assessment  </p>
            <img src={rigntIcon} alt="" />
            <p className={innertitle ? ' ' : 'active_page'}>{title}</p>
            {innertitle && <>
                <img src={rigntIcon} alt="" />
                <p className='active_page'>{innertitle}</p>
            </>

            }
        </div>
    )
}

export default InnerHeader