export const LOGIN = "user/login";
export const SIGNUP = "user/signup";
export const VIEWPROFILE = "/user/profile";
export const ADDPLANT = "/plant";
export const EDITPROFILE = "user/updateProfile";
export const SUPLIER = "supplyChain";
export const VIEWSUPLIER = "supplyChain";
export const ASSESSMENT_ONE = "assessment_scope1";
export const ASSESSMENT_FILTER = "assessment_scope1/scope1filter";
export const MONTH = "filter/monthList";
export const ADDASSESMENT = "assessment_scope2";
export const ASSESSMENT_TWO_FILTER = "/assessment_scope2/scope2filter";
export const ASSESSMENT_THREE_FILTER = "/assessment_scope3/scope3filter";
export const ASSESSMENT_THREE = "/assessment_scope3";
export const YEAR_DATA = "/assessment_scope1/currentYear";
export const SUBMIT = "scopeSubmit";
export const UPDATESUBMIT = "updateSubmit";
export const UPDATESUPPLY = "supplyChain";
export const REPORT = "report";
export const FILTER = "scope/filterScope";
export const ADDCEMA = "assessment_cema";
export const ScopeFilter = "/filter";
export const AssignmentPage = "/assessment/new_assessment_page";
export const ViewScopeData = "/scope/filterScopeData";
export const ViewPlant = "/plant";
export const Plantedit = "/plant/updatePlant/";
export const DeletePlant = "/plant/";
export const PostPlant = "/plant";
export const YEAR_FILTER = "/scope";
export const FilterChecker = "/scope/filterCheck/";
export const CEMA = "assessment/carbonEmissionList";
export const POSTREPORT = "report";
export const GetCEMA = "assessment_cema/cemafilter/";
export const GETASSURANCE = "assurance";
export const GETSTATUS = "scope/filterStatus";
export const GETSCOPEOPTION = "scopeEmission";
export const GEtDATASTATUS = "viewScope/checkData";
export const GETASSEMENT = "viewScope/viewPastUserScope";
export const EmailVerfication = "user/emailVerfication";
export const DeleteScope = "scope";
export const resendOTP = "user/resendOTP";
export const PaymentConfirmation = "user/paymentConfirmation";
export const Postsupplyfile = "supplyChain/upload";
export const REFRESH_TOKEN = "user/access-token";
export const ViewGraph = "view_graph_chat";
export const EditRequest = "editRequest";
export const Contact = "/contact";
export const GETASSURANCEFILE = "Data/getAssuranceFile/";
export const GETREPORTFILE = "report/";
export const RECENTACTIVITY = "recentActivity/";
export const PAYMENT = "/payments/newPayment";
export const DOWNLOADREPORT = "/downloadReport/";
export const NEWSASSEMENT = "/viewScope/checkCurrentYearAssessment/";
export const FINANCIALYEAR = "/financialYear"; 
export const SCOPE3DATA = "categoryMaterial/getdataList"; 
export  const GETCATEGORY = "categoryMaterial/";
export  const GETEMISSION = "scopeEmission/getmaterial";


