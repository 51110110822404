import React from 'react'
import Backbtn from '../../../Components/Common/Backbtn'
import Descriptions from '../../../Components/Common/Descriptions'
import InnerHeader from '../../../Components/Common/InnerHeader'
import InnerTabe from '../../../Components/Common/InnerTabe'

import WasteMaterialplant from '../../../Components/Common/ScopeThreeTables/wastematerial/WasteMaterialplant'

const WasteMaterial = () => {
    return (
        <div>
            <InnerHeader  innertitle="Waste Materials" title="Scope 3"  />
            <div className='px-16'>
                <Backbtn title="Waste Materials" />
                <Descriptions discrip="" />
                {/* <InnerTabe /> */}
                <WasteMaterialplant />
            </div>
        </div>
    )
}

export default WasteMaterial